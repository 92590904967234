// @import 'scss/globals';

.Option {
    // border: 3px solid var(--brand-color-5);
    margin: 0.2rem;
    text-align: center;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    // background-color: transparent;

    text-transform: initial;
}

.selected {
    --background-color: var(--brand-color-5);
    --color: var(--text-color-contrast);
    opacity: 0.85 !important;

    &.saved {
        &:after {
            top: 3px;
            left: 3px;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
        }

        &:before {
            border: 3px solid black !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.notsaved {
        &:before {
            border: 3px solid transparent;
        }
    }
}

.notselected {
    --background-color: var(--brand-color-9);
    --color: var(--text-color-contrast);
    opacity: 0.3 !important;

    &:before {
        border: 3px solid var(--brand-color-9);
    }
}

.correct {
    --background-color: var(--brand-color-4);
    --color: var(--text-color-contrast);
    &:before {
        border: 3px solid var(--brand-color-4);
    }
}

.incorrect {
    --background-color: var(--brand-color-1);
    --color: var(--text-color-contrast);
    &:before {
        border: 3px solid var(--brand-color-1);
    }
}
