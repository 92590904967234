@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.GroupsQuestProgress {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

// TeamProgress

.TeamProgress {
    // margin-bottom: 1rem;
}

.TeamProgressHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.TeamProgressTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    // font-size: $h5-font-size;
}

.TeamProgressPeopleCompleted {
    font-size: $h6-font-size;
    text-align: right;
}

.TeamProgressBar {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

// Group Progress

.GroupsProgress {
    // margin-bottom: 1rem;

    overflow: hidden;
    z-index: 1;
    position: relative;
}

.isTeamProgressOpen {
    max-height: 100%;
    transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.isTeamProgressClosed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.GroupsProgressHeader {
    margin-bottom: 0.5rem;
}

.GroupsProgressTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
}

.GroupProgress {
    margin-bottom: 1rem;
}

.GroupProgressBar {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
}

.GroupsButton {
    flex-shrink: 0;
}

.GroupHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.GroupLabel {
    margin: 0;
    font-weight: normal;
    color: var(--brand-color-11);
}

.GroupPeopleCompleted {
    font-size: $h6-font-size;
    text-align: right;
}

.MyGroups {
    margin-bottom: 1rem;
}

// .OtherGroups {
// }
