@import 'scss/globals';

.GroupsDialog {
    display: flex;
}

.GroupsList {
    display: flex;
    flex-direction: column;

    div:last-child {
        border-bottom: none;
    }
}

.GroupItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
}

.GroupName {
    font-family: var(--header-font-family);
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    color: var(--brand-color-5);
    // color: var(--text-color);
    padding-top: 3px;
}

.GroupIcon {
    color: var(--brand-color-5);
}

.DialogTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.GroupDetails {
    display: flex;
    gap: 0.5rem;
}
