@import 'scss/globals';

.ManagementMembers {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
    margin-bottom: 2rem;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

.MembersStat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MembersAmount {
    color: var(--text-color-contrast);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
    padding-top: 3px;
}

.MembersIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--text-color-contrast);
}

.MemberList {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li:last-child {
        border-bottom: none;
    }
}

.MembersListHeader {
    display: flex;
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 0.5rem;

    & > h3 {
        color: var(--category-cover-title-color);
        font-weight: var(--font-weight-bold);
        font-size: $h6-font-size;
        line-height: 1.5;
    }
}

.MemberListName,
.MemberListGroup {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    svg {
        color: var(--brand-color-5);
    }
}

.MemberListRole {
    align-items: center;
    display: flex;
    flex: 1 1 0px;
    justify-content: space-between;
    margin-right: 1rem;
}

.MemberListDateAdded {
    display: flex;
    align-items: center;
    width: 180px;
}

.InvitedListStatus {
    display: flex;
    align-items: center;
    width: 180px;
}

.MemberListDateAdded {
    color: var(--category-cover-title-color);
    flex: 1;

    & > h3 {
        flex: 0;
    }
}

.MemberPagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.PageNumber {
    color: var(--brand-color-12);
}

.InvitedUsersHeader {
    display: grid;
    grid-template-columns: 1.5fr repeat(4, 1fr);
}

.InvitedUserControls {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.MembersContainer {
    display: flex;
    gap: var(--card-gap);
}
