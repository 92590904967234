.EditDefaultImage {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
}

.Info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Title {
    color: var(--brand-color-5);
}

.Description {
}

.Container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
}

.Image {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    padding: 1rem;
    overflow: hidden;
}

.FileUploader {
}

.DimensionsAndFormat {
    align-self: flex-end;
    color: var(--brand-color-5);
    font-style: italic;
}

.TransparentBackground {
    background: transparent;
}
