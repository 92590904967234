@import 'scss/globals';
///

.GroupsList {
    display: flex;
    flex-direction: column;

    div:last-child {
        border-bottom: none;
    }
}

.Groups {
    margin-bottom: 1rem;
}

.PotentialGroups {
    margin-bottom: 0;
}

.GroupsDetails {
    display: flex;
    gap: 0.5rem;
}

.GroupsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.GroupsTitle,
.GroupsAmount {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.GroupsTitle {
    margin-bottom: 0;
}

.GroupsStat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.GroupsIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--brand-color-5);
}

.GroupsCards {
    font-size: $h6-font-size;
    text-align: right;
    color: var(--brand-color-5);
}

.GroupItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
}

.GroupAvatar {
    margin-right: 0.5rem;
    display: flex;

    color: var(--brand-color-5);

    :global {
        .icon {
            width: 30px;
            height: 30px;
        }
    }

    // svg {
    //     width: 100%;
    //     height: 100%;
    // }
}

.GroupName,
.GroupCards {
    font-family: var(--header-font-family);
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    color: var(--brand-color-5);
    // color: var(--text-color);
    padding-top: 3px;
}
