@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.QuestDetail {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.QuestDetailHeader {
    margin-bottom: 3rem;
}

.QuestDetailContent {
    display: flex;
    flex-direction: column-reverse;

    gap: 2rem;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.QuestTitle {
    // margin: 0;
    color: var(--text-color-contrast);
}

.QuestSubtitle {
    align-self: flex-start;
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.QuestCover {
    display: flex;
    flex-direction: column;
    // margin-bottom: 1rem;
    // @include media-breakpoint-up(md) {
    //     margin-right: 1.75rem;
    // }
}

.QuestCoverCard {
    padding: 0;
    position: relative;

    min-width: 300px;

    &.in_progress,
    &.ended {
        height: auto;
    }

    position: sticky;
    top: 1rem;
}

.QuestCoverCard::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px var(--shadow-color-light);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.QuestCoverCard:hover::after {
    opacity: 1;
}

.CoverImage {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 1rem;

    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);
}

.QuestStats {
    --quest-stats-color: var(--text-color-contrast);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-top: 0.25rem;
}

.QuestStat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    color: var(--quest-stats-color);

    svg {
        font-size: 1rem;
        // width: 100px;
    }
}

.CalendarContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.QuestStatLabel {
    font-size: 0.813rem;
}

.QuestDates {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.StartDate,
.EndDate {
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-weight-bold);
    color: var(--brand-color-5);

    // @include media-breakpoint-down(sm) {
    //     flex-direction: column;
    // }
}

.DateSeparator {
    margin: 0.5rem;
    color: var(--brand-color-5);
}

.QuestTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;

    font-size: 0.813rem;

    pointer-events: none;

    &.awaiting {
        font-style: italic;
    }

    &.in_progress {
        color: var(--quest-stats-color);
    }

    &.ended {
        color: var(--quest-stats-color);
    }

    &.weeksLeft {
        color: var(--quest-stats-color);
    }

    &.daysLeft {
        color: var(--quest-stats-color);
    }

    &.hoursLeft {
        color: var(--quest-stats-color);
    }
}

.TaskBar {
    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);

    display: grid;
    align-items: center;
    justify-items: center;

    font-size: $h7-font-size;
    font-weight: var(--font-weight-bold);
    padding: 10px;
    color: var(--text-color-contrast);

    pointer-events: none;

    &.awaiting {
        background: var(--brand-color-15);
    }

    &.ended {
        background: linear-gradient(180deg, var(--brand-color-2) 0%, var(--brand-color-3) 100%);
    }

    &.weeksLeft {
        background: var(--brand-color-5);
    }

    &.daysLeft {
        background: var(--brand-color-2);
    }

    &.hoursLeft {
        background: var(--brand-color-1);
    }

    &.failed {
        background: var(--brand-color-9);
    }

    &.completed {
        background: var(--brand-color-4);
    }
}

.TasksListContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    // padding: 0 var(--box-padding-small-leftright);
    margin-bottom: var(--box-padding-small-topbottom);

    @include media-breakpoint-up(sm) {
        // padding: 0 var(--box-padding-large-leftright);
        margin-bottom: var(--box-padding-large-topbottom);
    }
}

.TasksListHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 1rem;
    margin-bottom: 0.25rem;
}

.TasksListTitle {
    color: var(--brand-color-11);
    font-weight: var(--font-weight-bold);
    font-size: $font-size-base;
    margin: 0;
    margin-bottom: 0.5rem;
}

.TasksList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    &.ended {
        filter: grayscale(100%) opacity(60%);
    }
}

.TasksListDone {
    font-size: $h6-font-size;
    text-align: right;
}

.Description {
    margin-bottom: 1rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.DescriptionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;
}

.DescriptionTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    // font-size: $h4-font-size;
}

.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Element {
    margin-bottom: 1rem;
}
