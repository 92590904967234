@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.ProfileEditDetailForm {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.ProfileCover {
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
        margin-right: 1.5rem;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.InputContainer {
    width: 100%;
}

.EmailFormGroup {
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
}

.Emailaddress {
    width: 100%;
}

.ShowEmailInput {
    display: flex;
    align-self: flex-end;

    label {
        margin-bottom: 0;
    }
}

.FormElement {
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;

    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.formDelete {
    margin-right: auto;
}

.RequestText {
    font-size: 0.875rem;
    a {
        font-size: 0.875rem;
    }
}
