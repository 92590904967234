.TextAreaField {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0;

    // &.error {
    //     margin-bottom: 0.2rem;
    // }
}

.error {
    border-color: var(--brand-danger-color);
}

.errorMessage {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
    flex: 1;
}

.BelowField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
}
