@import 'scss/globals';

.QuestCover {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    perspective: 2000;
    transform-style: preserve-3d;
}

.QuestCoverCard {
    padding: 0;
    position: relative;
    will-change: transform;

    cursor: pointer;

    button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        padding-left: 15px;
        padding-right: 15px;
    }
}

.QuestCoverCard::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px var(--shadow-color-light);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.QuestCoverCard:hover::after {
    opacity: 1;
}

.HitArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;

    align-items: stretch;
    justify-content: space-between;
}

.CoverImage {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    // height: 175px;
    overflow: hidden;
    margin-bottom: 1rem;

    flex: 1;

    &.ended {
        filter: grayscale(100%) hue-rotate(0.1deg);
    }
}

.QuestTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;

    font-size: 0.813rem;
    // margin-bottom: var(--box-item-margin);

    pointer-events: none;

    &.awaiting {
        font-style: italic;
    }

    &.in_progress {
        font-weight: var(--font-weight-bold);
        color: var(--brand-color-5);
    }

    &.ended {
        font-weight: var(--font-weight-bold);
        color: var(--brand-color-9);
    }

    &.weeksLeft {
        color: var(--brand-color-5);
    }

    &.daysLeft {
        color: var(--brand-danger-color);
    }

    &.hoursLeft {
        color: var(--brand-danger-color);
    }

    svg {
        font-size: 1rem;
    }
}

.TitleBar {
    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);

    padding: 10px;

    &.weeksLeft {
        background: var(--brand-color-5);
    }

    &.daysLeft {
        background: var(--brand-color-2);
    }

    &.hoursLeft {
        background: var(--brand-color-1);
    }

    &.awaiting {
        background: var(--brand-color-15);
    }

    &.ended {
        background: linear-gradient(180deg, var(--brand-color-2) 0%, var(--brand-color-3) 100%);
    }

    &.in_progress {
        background: var(--brand-alert-color);
    }

    &.failed {
        background: var(--brand-color-9);
    }

    &.completed {
        background: var(--brand-color-4);
    }
}

.Title {
    font-size: 1.25rem;
    font-weight: var(--font-weight-bold);

    color: var(--text-color-contrast);

    pointer-events: none;

    text-align: center;

    filter: drop-shadow(0px 1px 1px #00000075);
}

.TaskBar {
    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);

    display: grid;
    align-items: center;
    justify-items: center;

    font-size: $h7-font-size;
    font-weight: var(--font-weight-bold);
    padding: 10px;
    color: var(--text-color-contrast);

    pointer-events: none;

    &.awaiting {
        background: var(--brand-color-15);
    }

    &.ended {
        background: linear-gradient(180deg, var(--brand-color-2) 0%, var(--brand-color-3) 100%);
    }

    &.weeksLeft {
        background: var(--brand-color-5);
    }

    &.daysLeft {
        background: var(--brand-color-2);
    }

    &.hoursLeft {
        background: var(--brand-color-1);
    }

    &.failed {
        background: var(--brand-color-9);
    }

    &.completed {
        background: var(--brand-color-4);
    }
}

.ActionButton {
    margin-top: 0.75rem;
    font-size: $h5-font-size;
    font-weight: var(--font-weight-bold);
    // color: var(--brand-color-8);
    align-self: center;
}

.TopCardSection {
    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);
}

.Content {
    // flex: 1 0;
    min-height: 120px;
    margin-bottom: 1rem;
}

.Description {
    font-size: 0.938rem;
    padding: 0 var(--box-padding-large-leftright);
}

.QuestStats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0 10px 10px var(--box-padding-large-leftright);
}

.QuestStatsLeft,
.QuestStatsRight {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.QuestStat {
    --size: 50px;

    width: var(--size);
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.QuestStatLabel {
    font-size: 0.813rem;
}

.TasksLeft {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--brand-color-2);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 25px;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    color: var(--text-color-contrast);
    transform: translate(12px, -12px) translateZ(10px);
    box-shadow: 0px 0px 0px var(--shadow-color-light);

    transition: 0.2s all cubic-bezier(0.18, 0.89, 0.32, 1.28);

    min-width: 25px;
    min-height: 25px;

    pointer-events: none;

    &.new {
        background: var(--brand-danger-color);
    }

    &.inprogress {
        background: var(--brand-danger-color);
    }

    &.completed {
        background: var(--brand-color-4);
        padding: 0;
    }

    &.hover {
        transform: translate(15px, -15px) translateZ(10px);
        box-shadow: -6px 6px 7px var(--shadow-color-light);
    }
}

.CheckMark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    svg {
        width: 80px;
        height: 80px;
        position: absolute;
        z-index: 3;
        transition: 0.2s all cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }

    &.hover {
        svg {
            transform: translate(0, -5px) translateZ(20px);
            filter: drop-shadow(-6px 6px 7px var(--shadow-color-light));
            // box-shadow: -6px 6px 7px var(--shadow-color-light);
        }
    }
}

.CheckMarkBackground {
    z-index: 1;
    background: var(--brand-color-4);
    opacity: 0.75;
    width: 100%;
    height: 100%;
}
