@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.InputContainer {
    width: 100%;
}

.InputContent {
    margin-bottom: 1rem;
}

.DeckDetail {
    display: flex;
}

.DeckCoverWrapper {
    margin-right: 1.5rem;

    :global {
        .cover {
            margin: 0;
            margin-top: 0.3rem;
        }
    }
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;

    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}

.flexGap {
    // --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    // margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    // width: calc(100% + var(--gap));
    width: 100%;
}

// .flexGap > * {
//     margin: var(--gap) 0 0 var(--gap);
// }

.formDelete {
    margin-right: auto;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

////
.Navigation {
    // position: sticky;
    // top: 1rem;
    // transform: translateY(100%);

    display: flex;
    flex-direction: column;
    // position: sticky;
    // bottom: 25vh;
    // z-index: var(--z-index-cards-navigation-bar);
    // width: 100%;
    // height: 0px;
    // transform: translateY(-32px);

    // @include media-breakpoint-up(sm) {
    //     bottom: 33vh;
    // }

    // pointer-events: none;

    // top: 0;
    // position: absolute;

    margin-bottom: 1rem;
}

.NavButtons {
    margin-bottom: 0.5rem;
}

.NavButtons,
.CardAddButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

.NavButton {
    --size: 30px;
    width: var(--size);
    // width: 30px;
    // height: 30px;

    @include media-breakpoint-up(sm) {
        --size: 50px;
        // width: 50px;
        // height: 50px;
    }

    padding-left: 0;
    padding-right: 0;

    &-enabled {
        opacity: 1;
        pointer-events: initial;

        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &-disabled {
        opacity: 0;
        pointer-events: none;

        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    // Extra hit area for the button
    // TODO: no longer works with new buttons. So create new divs for hitarea?
    // &:after {
    //     content: '';
    //     position: absolute;
    //     width: 50px;
    //     height: 75vh;
    // }
}
////

.ArrowIcon {
    @include media-breakpoint-up(sm) {
        font-size: $h3-font-size;
    }
}

.Previous {
    .ArrowIcon {
        margin-right: 3px;
    }
}

.Previous.NavButton-enabled {
    transform: translateX(calc(-100% - 10px));

    @include media-breakpoint-up(sm) {
        transform: translateX(calc(-100% - 12px));
    }
}

.Previous.NavButton-disabled {
    transform: translateX(calc(-100%));
}

.Next.NavButton-enabled {
    // transform: translateX(calc(100% + 10px));

    // @include media-breakpoint-up(sm) {
    //     transform: translateX(calc(100% + 12px));
    // }
}

.Next.NavButton-disabled {
    // transform: translateX(100%);
}

.CardsGroup {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 20px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
        padding: 0;
    }

    // touch-action: none;
}

.TransitionGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;

    transform-style: preserve-3d;
}

.perspective-ismobile {
    transform: perspective(3200px);
}

.perspective-isdesktop {
    transform: perspective(800px);
}

.EditButton {
    pointer-events: initial;

    &.Previous {
        opacity: 0;
        pointer-events: none;
    }
}

.NewCardButton {
    align-self: flex-end;
    margin-bottom: 1rem;
}

.SourceCardListTitle {
    border-bottom: 1px solid var(--divider-color);
}

.SourceCardList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.SourceCardItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
