// @import 'scss/globals';

.Quests {
    flex: 1;

    &.no-content {
        flex: 0;
    }

    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: var(--quests-max-width);
    margin-bottom: 1.5rem;

    .title {
        color: var(--text-color-contrast);
        margin-bottom: 0;
    }

    .Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.75rem;
    }
}

.Loader {
    flex: 1;
    width: 100%;
}

.QuestCoverReferenceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
