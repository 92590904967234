@import 'scss/globals';

.ElementActionButtons {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
}

.QuestionFormik {
    display: flex;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.FormButtons {
    display: flex;
    gap: 0.5rem;
}

// CSS Horizontal divider with height of 3px and color from
.Divider {
    height: 3px;
    width: 100%;
    background-color: var(--divider-color);
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.OptionSwitch {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.Options {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.OptionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    label {
        margin-bottom: 0;
    }
}

.OptionInput {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.InputLabel {
    font-size: $h7-font-size;
    font-weight: normal;
}

.BodyInput {
    display: flex;
    gap: 1rem;
}
