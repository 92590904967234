@import 'scss/globals';

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.ActiveQuests,
.UpcomingQuests,
.ExpiredQuests {
    .title {
        color: var(--text-color-contrast);
        margin-bottom: 0;
    }
}
