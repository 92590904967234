// @import 'scss/globals';

.List {
    list-style: none;

    & > li {
        display: inline-block;
        margin: 20px;
    }

    a {
        color: var(--dropdown-link-text-color);

        &:hover,
        &:active,
        .my-active {
            color: var(--primary-color);
            text-decoration: underline;
        }
    }

    // button {
    //     color: var(--dropdown-button-text-color);
    // }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 0;

    background-color: var(--dropdown-background-color);
    color: var(--dropdown-text-color);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.45);

    &.isMobile {
        width: 100%;
        border-radius: 0;
        text-align: center;
    }

    &.isDesktop {
        position: absolute;
        width: 200px;
        top: calc(100% + 6px);
        right: 0;
        z-index: var(--z-index-top-navigation-dropdown);
        overflow: hidden;
        text-align: left;
    }

    &.isOpen {
        transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);

        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
    }

    &.isClosed {
        transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);

        opacity: 0;
        pointer-events: none;
        transform: translateY(20px);
    }

    .ListSectionDivider {
        position: absolute;
        bottom: 0;

        width: 100%;
        height: 1px;

        background-color: var(--brand-color-12);
    }

    &.alt {
        background-color: var(--dropdown-alt-background-color);
        color: var(--dropdown-alt-text-color);

        a {
            color: var(--dropdown-alt-link-text-color);
        }

        .ListSectionDivider {
            background-color: var(--brand-color-12);
        }
    }
}

.ListSection {
    position: relative;
    width: 100%;
    padding: 15px 0;
}

.ListSectionHeader {
    margin: 0 20px;
}

.ListItem {
    &.link {
        margin: 0 20px;
    }
}

.ListItemButton {
    white-space: nowrap;

    &.isDekstop {
        justify-content: flex-start;
    }

    &.isMobile {
        justify-content: center;
    }
}

.Navlink {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.NavIcon {
    width: 20px;
}
