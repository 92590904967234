@import 'scss/globals';

.DeckCoverContainer {
    position: relative;
    // margin: 0.75rem;
    margin-bottom: 1rem;

    &.hover {
        z-index: 1;
    }
}

.DeckCover {
    z-index: 1;
    padding: 0;
    position: relative;

    transform: translateZ(0);
    will-change: transform;
    cursor: pointer;

    &.disabled {
        cursor: auto;
    }
    // &.multiple-cards {
    //     transform-style: preserve-3d;
    // }
}

.DeckCoverDropshadow {
    position: relative;
}

.DeckCoverDropshadow::after {
    content: '';
    border-radius: var(--deck-border-radius);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--shadow-color-dark);
    box-shadow: 0px 0px 60px 0px var(--shadow-color-dark);
    opacity: 0;
    transition: all 0.24s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(0.9) translateZ(-100px);
}

.DeckCoverDropshadow.hover::after {
    opacity: 1;
}

.Cover {
    position: absolute;
    top: -5px;
    z-index: 1;
    padding: 0;

    transition: 0.2s transform cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &.multiple-cards {
        &.hover {
            transform: translateX(10px) translateY(-5px) translateZ(0px) rotate(3deg);
        }
    }

    &.single-card {
        &.hover {
            transform: scale(1.06);
        }
    }
}

.Cover::after {
    content: '';
    border-radius: var(--deck-border-radius);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 7px var(--shadow-color-light);
    opacity: 1;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.DeckCoverExtra {
    position: absolute;
    top: 5px;
    z-index: 0;
    padding: 0;

    transition: 0.2s transform cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &.multiple-cards {
        &.hover {
            // top: 0;
            transform: translateX(-10px) translateY(-15px) translateZ(0px) rotate(-3deg);
        }
    }
}

.FilterActive {
    filter: grayscale(0) opacity(0.345678) brightness(1);
    cursor: auto;
}

.CoverImage {
    width: 100%;
    height: 100%;

    border-radius: var(--deck-border-radius);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Header {
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin-top: 0.6rem;

    max-width: var(--deck-width-large);

    gap: 0.2rem;
    // justify-content: space-between;
}

.Title {
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    color: var(--text-color-contrast);
    padding-top: 3px; // Font offset needed for vertical centering
    margin-bottom: 0;
}

.Subtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

// TODO: Create Avatar Component
$avatar-size: 30px;
$avatar-background-color: var(--brand-color-12);
.Avatar {
    flex-shrink: 0;

    width: $avatar-size;
    height: $avatar-size;
    border-radius: 50%;
    background: $avatar-background-color;
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    svg path {
        fill: var(--brand-color-8);
    }
}

.CardsLeft {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--brand-color-2);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 25px;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    color: var(--text-color-contrast);
    transform: translate(12px, -12px) translateZ(10px);
    box-shadow: 0px 0px 0px var(--shadow-color-light);

    transition: 0.2s all cubic-bezier(0.18, 0.89, 0.32, 1.28);

    min-width: 25px;
    min-height: 25px;

    pointer-events: none;

    &.new {
        background: var(--brand-color-1);
    }

    &.inprogress {
        background: var(--brand-color-2);
    }

    &.completed {
        background: var(--brand-color-4);
        padding: 0;
    }

    &.hover {
        // transform: translate(22px, -12px) translateZ(50px);
        box-shadow: -6px 6px 7px var(--shadow-color-light);
    }
}

.PublicationStateLabel {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    margin: auto 0;
    position: absolute;
    top: 20px;
    right: -10px;
}

.TagItem {
    color: var(--text-color-contrast);
    background-color: var(--button-background-color);
    padding: 1px 14px 1px 10px;
    // border-radius: 25px 0px 0px 25px;
    border-radius: 0px 25px 25px 0px;

    font-size: 0.8rem;
    display: inline;
    max-height: 20px;
    overflow: hidden;
}

.TagsList {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
    justify-content: flex-start;
}
