@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.GroupCover {
    display: flex;
    flex-direction: column;
    // margin-bottom: 1rem;
    cursor: pointer;
}

.GroupCoverCard {
    padding: 0;
    position: relative;
    will-change: transform;
}

.GroupCoverCard::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px var(--shadow-color-light);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.GroupCoverCard:hover::after {
    opacity: 1;
}

.HitArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    padding: var(--box-padding-small-topbottom) var(--box-padding-small-leftright);
}

// .GroupCoverContainer {
//     position: relative;
//     margin: 0.75rem;
//     margin-bottom: 1rem;
//     cursor: pointer;

//     &.hover {
//         z-index: 1;
//     }

//     @include media-breakpoint-up(sm) {
//         margin: 1rem;
//     }
//     @include media-breakpoint-up(lg) {
//         margin: 1.5rem;
//     }
// }

// .GroupCover {
//     padding: 0;
//     position: relative;

//     transform: translateZ(0);
//     will-change: transform;

//     // &.multiple-cards {
//     //     transform-style: preserve-3d;
//     // }
// }

// .GroupCoverDropshadow {
//     position: relative;
// }

// .GroupCoverDropshadow::after {
//     content: '';
//     border-radius: 5px;
//     position: absolute;
//     z-index: 0;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: var(--shadow-color-dark);
//     box-shadow: 0px 0px 60px 0px var(--shadow-color-dark);
//     opacity: 0;
//     transition: all 0.24s cubic-bezier(0.165, 0.84, 0.44, 1);
//     transform: scale(0.9) translateZ(-100px);
// }

// .GroupCoverDropshadow.hover::after {
//     opacity: 1;
// }

.Cover {
    position: absolute;
    z-index: 1;

    padding: var(--box-padding-small-topbottom) var(--box-padding-small-leftright);

    @include media-breakpoint-up(sm) {
        padding: var(--box-padding-large-topbottom) var(--box-padding-large-leftright);
    }

    pointer-events: none;

    transition: 0.2s transform cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &.single-card {
        &.hover {
            transform: scale(1.06);
        }
    }
}

.GroupCoverExtra {
    position: absolute;
    top: 5px;
    z-index: 0;
    padding: 0;

    transition: 0.2s transform cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &.multiple-cards {
        &.hover {
            // top: 0;
            transform: translateX(-10px) translateY(-15px) translateZ(0px) rotate(-3deg);
        }
    }
}

.CoverImage {
    width: 100%;
    height: 100%;

    border-radius: var(--deck-border-radius);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Header {
    display: flex;
    align-items: center;

    max-width: var(--deck-width-large);
    height: 40px;
    margin-bottom: 1rem;
}

.Title {
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    color: var(--text-color);
    // padding-top: 3px; // Font offset needed for vertical centering
    margin-bottom: 0.25rem;
    line-height: 1.2;
}

.Subtitle {
    font-family: var(--header-font-family);
    font-weight: var(--header-font-weight);
    // font-weight: 200;
    font-style: italic;
    line-height: 1;
    font-size: $h7-font-size;
    color: var(--text-color);
    // padding-top: 3px;
}

.Description {
    font-size: $h6-font-size;
}

// TODO: Create Avatar Component
$avatar-size: 40px;
.Avatar {
    flex-shrink: 0;

    width: $avatar-size;
    height: $avatar-size;
    border-radius: 50%;
    background: var(--brand-color-5);
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: transform 0.35s cubic-bezier(0.19, 1, 0.22, 1);
    padding: 8px;
}

.CardsLeft {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--brand-color-2);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 25px;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    color: var(--text-color-contrast);
    transform: translate(12px, -12px);
    box-shadow: 0px 0px 0px var(--shadow-color-light);

    transition: 0.2s all cubic-bezier(0.18, 0.89, 0.32, 1.28);

    min-width: 25px;
    min-height: 25px;

    &.new {
        background: var(--brand-color-1);
    }

    &.inprogress {
        background: var(--brand-color-2);
    }

    &.completed {
        background: var(--brand-color-4);
        padding: 0;
    }

    &.hover {
        transform: translate(22px, -12px) translateZ(50px);
        box-shadow: -6px 6px 7px var(--shadow-color-light);
    }
}
