.RoleEditDetailForm {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.RolePermissions {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;

    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
}
