.ForkQuestion {
    display: flex;
    flex: 1 0;
    flex-direction: column;

    padding: 0 1.5rem;

    @media (min-width: 576px) {
        padding: 0 5rem;
    }
}

.Loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Body {
    margin-bottom: 0.5rem;
    font-weight: var(--font-weight-bold);
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.formActions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: flex;
    flex-wrap: wrap;
}

// .flexGap > * {
//     margin: var(--gap) 0 0 var(--gap);
// }

.InputWrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    &.saved {
        textarea {
            border-color: var(--brand-color-4);
        }
    }
}

.Hint {
    font-size: 11px;
}

textarea {
    height: 200px !important;
    resize: none;
}

.Saved {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
        margin: 0.2rem;
        color: var(--brand-color-4);
        line-height: 1;
    }

    svg path {
        fill: var(--brand-color-4);
    }
}

.Feedback {
    .correct {
        color: var(--brand-color-4);
    }

    .incorrect {
        color: var(--brand-color-1);
    }

    border-radius: 5px;
    background-color: var(--brand-color-13);
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 1rem;
}
