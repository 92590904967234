.Poll {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;

    @media (min-width: 576px) {
        padding: 0 5rem;
    }

    &.isEdit {
        padding: 0;
    }
}

.Saved {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
        margin: 0.2rem;
        color: var(--brand-color-4);
        line-height: 1;
    }

    svg path {
        fill: var(--brand-color-4);
    }
}

.QuestionHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 1rem;

    @media (min-width: 576px) {
        margin-bottom: 1.5rem;
        gap: 2rem;
    }

    @media (min-width: 768px) {
        margin-bottom: 2rem;
        gap: 3rem;
    }
}

.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.OpinionOthers {
    align-self: flex-end;
    text-align: right;
}

.formActions {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: flex;
    flex-wrap: wrap;
}

.Feedback {
    .correct {
        color: var(--brand-color-4);
    }

    .incorrect {
        color: var(--brand-color-1);
    }

    border-radius: 5px;
    background-color: var(--brand-color-13);
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 1rem;
}

.QuestionForm {
    &.blur {
        filter: blur(1rem);
        pointer-events: none;
    }
}

.StartButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
