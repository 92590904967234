// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1.75rem;

    &:after {
        content: none;
        flex: auto;
    }

    @include media-breakpoint-up(sm) {
        justify-content: flex-start;

        &:after {
            content: '';
            flex: auto;
        }
    }

    // margin: -0.5rem; // -(Gap of items)

    // @include media-breakpoint-up(sm) {
    //     margin: -1rem;
    // }
    // @include media-breakpoint-up(lg) {
    //     margin: -1.5rem;
    // }
}

.GroupReferenceContainer {
    display: flex;

    &.hover {
        z-index: 1;
    }
}

.GroupSection {
    margin-bottom: 2rem;
}

.Header {
    margin-bottom: 1rem;
}

.Title {
    color: var(--text-color-contrast);
}

.NoGroup {
    color: var(--text-color-contrast);
    padding: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    width: 100%;
}
