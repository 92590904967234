@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');

$assetPath: '../assets/fonts';

/**
***    Santral
**/

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Hairline.woff2') format('woff2'),
        url('#{$assetPath}/Santral-Hairline.woff') format('woff'),
        url('#{$assetPath}/Santral-Hairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Black.woff2') format('woff2'),
        url('#{$assetPath}/Santral-Black.woff') format('woff'),
        url('#{$assetPath}/Santral-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-ExtraLightItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-ExtraLightItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-LightItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-LightItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-HairlineItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-HairlineItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-HairlineItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-MediumItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-MediumItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Medium.woff2') format('woff2'),
        url('#{$assetPath}/Santral-Medium.woff') format('woff'),
        url('#{$assetPath}/Santral-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Book.woff2') format('woff2'), url('#{$assetPath}/Santral-Book.woff') format('woff'),
        url('#{$assetPath}/Santral-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Bold.woff2') format('woff2'), url('#{$assetPath}/Santral-Bold.woff') format('woff'),
        url('#{$assetPath}/Santral-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-BlackItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-BlackItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-BlackItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-BoldItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-BoldItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-ExtraBold.woff2') format('woff2'),
        url('#{$assetPath}/Santral-ExtraBold.woff') format('woff'),
        url('#{$assetPath}/Santral-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Light.woff2') format('woff2'),
        url('#{$assetPath}/Santral-Light.woff') format('woff'),
        url('#{$assetPath}/Santral-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-ExtraBoldItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-ExtraBoldItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-RegularItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-RegularItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Regular.woff2') format('woff2'),
        url('#{$assetPath}/Santral-Regular.woff') format('woff'),
        url('#{$assetPath}/Santral-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-ExtraLight.woff2') format('woff2'),
        url('#{$assetPath}/Santral-ExtraLight.woff') format('woff'),
        url('#{$assetPath}/Santral-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Thin.woff2') format('woff2'), url('#{$assetPath}/Santral-Thin.woff') format('woff'),
        url('#{$assetPath}/Santral-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-Ultra.woff2') format('woff2'),
        url('#{$assetPath}/Santral-Ultra.woff') format('woff'),
        url('#{$assetPath}/Santral-Ultra.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-ThinItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-ThinItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-SemiBold.woff2') format('woff2'),
        url('#{$assetPath}/Santral-SemiBold.woff') format('woff'),
        url('#{$assetPath}/Santral-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-SemiBoldItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-SemiBoldItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Santral';
    src: url('#{$assetPath}/Santral-UltraItalic.woff2') format('woff2'),
        url('#{$assetPath}/Santral-UltraItalic.woff') format('woff'),
        url('#{$assetPath}/Santral-UltraItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

/**
***    Proxima
**/

@font-face {
    font-family: 'Proxima Nova Alt Bl';
    src: url('#{$assetPath}/ProximaNovaA-Black.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaA-Black.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaA-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt Cn Rg';
    src: url('#{$assetPath}/ProximaNovaACond-Bold.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaACond-Bold.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaACond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt Lt';
    src: url('#{$assetPath}/ProximaNovaA-Light.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaA-Light.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaA-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt Cn Th';
    src: url('#{$assetPath}/ProximaNovaACond-Thin.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaACond-Thin.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaACond-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt Cn Bl';
    src: url('#{$assetPath}/ProximaNovaACond-Black.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaACond-Black.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaACond-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt Rg';
    src: url('#{$assetPath}/ProximaNovaA-Bold.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaA-Bold.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaA-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Alt Rg';
    src: url('#{$assetPath}/ProximaNovaA-Regular.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaA-Regular.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaA-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Lt';
    src: url('#{$assetPath}/ProximaNovaS-Light.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaS-Light.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaS-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('#{$assetPath}/ProximaNova-Regular.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNova-Regular.woff') format('woff'),
        url('#{$assetPath}/ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Rg';
    src: url('#{$assetPath}/ProximaNovaS-Bold.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaS-Bold.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaS-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Rg';
    src: url('#{$assetPath}/ProximaNovaS-Regular.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaS-Regular.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaS-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('#{$assetPath}/ProximaNova-Black.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNova-Black.woff') format('woff'),
        url('#{$assetPath}/ProximaNova-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('#{$assetPath}/ProximaNova-Extrabld.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNova-Extrabld.woff') format('woff'),
        url('#{$assetPath}/ProximaNova-Extrabld.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('#{$assetPath}/ProximaNova-Bold.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNova-Bold.woff') format('woff'),
        url('#{$assetPath}/ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Cn Th';
    src: url('#{$assetPath}/ProximaNovaCond-Thin.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaCond-Thin.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaCond-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Th';
    src: url('#{$assetPath}/ProximaNovaS-Thin.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaS-Thin.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaS-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('#{$assetPath}/ProximaNovaT-Thin.woff2') format('woff2'),
        url('#{$assetPath}/ProximaNovaT-Thin.woff') format('woff'),
        url('#{$assetPath}/ProximaNovaT-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
