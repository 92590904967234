@import 'scss/globals';
///

.Decks {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.DeckDetails {
    display: flex;
    gap: 0.5rem;
}

.PotentialDecks {
    margin-bottom: 0;
}

.DecksHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.DecksTitle,
.DecksAmount {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.DeckDescription {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.DecksTitle {
    margin-bottom: 0;
}

.DecksStat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DecksIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--brand-color-5);
}

.DecksCards {
    font-size: $h6-font-size;
    text-align: right;
    color: var(--brand-color-5);
}

.DeckItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
}

.DeckAvatar {
    margin-right: 0.5rem;
    display: flex;

    color: var(--brand-color-5);

    :global {
        .icon {
            width: 30px;
            height: 30px;
        }
    }

    // svg {
    //     width: 100%;
    //     height: 100%;
    // }
}

.DeckName,
.DeckCards {
    font-family: var(--header-font-family);
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    color: var(--brand-color-5);
    // color: var(--text-color);
    padding-top: 3px;
}

.AddButton {
    padding: 0;
}

.RemoveButton {
    padding: 0;
}

.BelowField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
}

.EditButton {
    --size: 30px;
}

.DeckControls {
    display: flex;
    gap: 0.5rem;
}

.ItemHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.4;

    flex: 0 0 auto;
}
