.OpenQuestionEditElement {
    width: 100%;

    // padding: 0 1.25rem;

    // @media (min-width: 576px) {
    //     padding: 0 3rem;
    // }
    &.isReorder {
        display: flex;
        align-items: center;
    }
}

.Loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    label {
        flex-grow: 1;
        margin: 0;
    }
}

.BodyInput,
.OptionInput {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.FormButtons {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-shrink: 0;
    gap: 1rem;

    z-index: 1; // Fix for capped buttons, dunno why

    button {
        margin: 0;
    }
}

.FormGroup {
    display: flex;
    gap: 1rem;
}

.OptionNumber {
    width: 40px;
    height: 40px;
    display: flex;
    place-items: center;
    justify-content: center;
    background-color: var(--brand-color-8);
    color: var(--brand-color-13);
    border-radius: 50%;
    margin-right: 1rem;
    flex-shrink: 0;
}

.AddOption {
    background-color: var(--brand-color-5);
}

.AddOptionButton {
    --radius: 10px;
    --background-color: var(--brand-color-18);
    --color: var(--brand-color-8);
    --border-color: var(--background-color);
    width: 100%;
    min-height: 50px;
    font-size: 1rem;
}
