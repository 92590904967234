.ElementAddButton {
}

.MenuGroupName {
}
.MenuGroupItem {
}
.MenuItem {
}

.MenuGroupName,
.MenuGroupItem,
.MenuItem {
    display: flex;
    gap: 0.5rem;
    svg {
        width: 30px;
    }
}
