@import 'scss/globals';

.DecksList {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    div:last-child {
        border-bottom: none;
    }
}

.Item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
    width: 100%;

    flex-direction: row;
    padding: 0.5rem 1rem;

    &:hover,
    &:active,
    &:focus {
        background-color: var(--element-edit-background-color);

        .ItemHandle {
            opacity: 1;
        }
    }

    position: absolute;
    width: 100%;
    cursor: move;
    user-select: none;
}

.DeckDetails {
    display: flex;
    gap: 0.5rem;
}

.ItemHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.4;

    flex: 0 0 auto;
}

.DeckName {
    font-family: var(--header-font-family);
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    color: var(--brand-color-5);
    // color: var(--text-color);
    padding-top: 3px;
}

.DeckControls {
    display: flex;
    gap: 0.5rem;
}
