.NavigationElement {
    display: flex;

    &.edit {
        background-color: var(--element-edit-background-color);
        flex-direction: column;
        --box-padding-large-leftright: 3rem;
        padding: var(--box-padding-large-topbottom) var(--box-padding-large-leftright) 2rem;
        gap: 1rem;
    }
}

.NavigationElementContainer {
    display: flex;
    flex-direction: column;

    .Title {
        margin-left: 3rem;
        margin-bottom: 1rem;
    }
}

.NavigationEdit {
    width: 100%;
}

.Navigation {
}

.NavigationPath {
    display: flex;
    flex-direction: column;

    gap: 1rem;

    width: 100%;
}

.NavigationEndpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1rem;

    width: 100%;
}

.EndpointLabel {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.ExclamationIcon {
    font-size: 1.5rem;
    color: var(--brand-danger-color);
}

.ButtonAddNavigationOption {
    width: 100%;
}

.PathSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: 100%;
}

.PathCardSelect {
}

.Header {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    & > label {
        flex-grow: 1;
        margin: 0;
    }
}

.ElementActions {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.AddOption {
    width: 100%;
}
