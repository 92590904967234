@import 'scss/globals.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.DeckActions {
    display: flex;

    flex-direction: column;
    gap: 1rem;

    transition: all 0.35s cubic-bezier(0.49, 0.8, 0, 1.28);
    z-index: var(--z-index-deck-actions-bar);

    // transform: translateY(0%);

    &.hidden {
        // transform: translateY(30px);
        opacity: 0;
        pointer-events: none;
        user-select: none;
    }
}

.Actionbar {
    // width: 100vw;
    width: 100%;

    border-radius: var(--box-border-radius);

    &.hidden {
        transform: translateY(30px);
        opacity: 0;
    }
}

.ActionbarStickyContainer {
    position: fixed;
    bottom: 0;
    left: 0px;
    right: 0px;

    transform: translateY(100%);

    &.isSticky {
        transform: translateY(0);
    }

    &.hidden {
        height: 0px;
    }
}

.ActionbarSticky {
}

.Actionbar,
.ActionbarSticky {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    --background-color: var(--box-background-color);

    background: var(--background-color);
    min-height: 50px;

    transition: transform 0.35s cubic-bezier(0.49, 0.8, 0, 1.28);

    &.canManage {
        &.published {
            --background-color: var(--brand-danger-color);
        }

        &.concept {
            --background-color: var(--brand-alert-color);
        }
    }

    --actionbar-page-padding: var(--page-padding);
    padding: 0.75rem var(--actionbar-page-padding);
}

.ActionbarSticky {
    // calc padding for page breakpoints

    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.2);

    --actionbar-breakpoint: 0px;

    @include media-breakpoint-up(sm) {
        --actionbar-breakpoint: 576px;
        --actionbar-page-padding: var(--page-padding);
        padding: 0.75rem calc((100vw - calc(var(--actionbar-breakpoint) - (var(--actionbar-page-padding) * 2))) / 2);
    }
    @include media-breakpoint-up(md) {
        --actionbar-breakpoint: 768px;
        --actionbar-page-padding: var(--page-padding-desktop);
    }
    @include media-breakpoint-up(lg) {
        --actionbar-breakpoint: 992px;
        --actionbar-page-padding: var(--page-padding-desktop);
    }
    @include media-breakpoint-up(xl) {
        --actionbar-breakpoint: 1000px;
        --actionbar-page-padding: 0px;
    }
}

.LeftActions,
.RightActions {
    display: flex;
    align-items: center;
    gap: 1rem;
    // flex-basis: 200px;
}

.LeftActions {
    justify-content: flex-start;
}

.RightActions {
    justify-content: flex-end;
}

.PublishedStateLabel {
    color: var(--text-color-contrast);
}

.ActionbarButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 0.5rem;
    gap: 0.5rem;

    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;

    &.hide {
        transform: translateY(100%);
        opacity: 0;
        pointer-events: none;
    }
}

.Previous,
.Next,
.CompleteDeckButton {
    flex-shrink: 0;
}

.CompleteDeckButton {
    --background-color: var(--brand-color-4);
    width: 100%;
}

// .ContinueButton {

//     --background-color: var(--brand-alert-color);

//     &.canManage {
//         --background-color: var(--button-alt-background-color, white);
//     }
// }

.Previous,
.Next {
    &.enabled {
        opacity: 1;
    }
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    &.hidden {
        visibility: hidden;
    }
}

.InfoContainer {
    display: flex;
    flex-direction: column;
    font-size: $h8-font-size;

    color: var(--text-color);

    &.canManage {
        color: var(--text-color-contrast);
    }

    @include media-breakpoint-up(sm) {
        font-size: $h7-font-size;
    }
}

.TitleContainer {
    display: flex;
    gap: 0.5rem;

    font-weight: bold;

    font-size: $h7-font-size;

    @include media-breakpoint-up(sm) {
        font-size: $h6-font-size;
    }
}
