// @import 'scss/globals';

.DropDownButton {
    flex-shrink: 0;
}

.DownArrow {
    color: white;
    font-size: 30px;
    margin-bottom: 0.35em;
}

.isClosed {
    transform: rotate(0);
}

.isOpen {
    transform: rotate(180deg);
}

.btn {
    flex-shrink: 0;
}
