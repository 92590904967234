.RegisterAccessCodeForm {
    display: flex;
    width: 100%;
    gap: 0.5rem;
}

.formActions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}
