.TimedInput {
    align-self: flex-end;

    display: flex;
    align-items: flex-start;
    gap: 0.5rem;

    label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}
