// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.EmailValidation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1 1 auto;
    // margin: 2rem 0;
}

.InviteAcceptForm {
    // margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }

    img {
        width: 0;
        max-width: 360px;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }
}

.Content {
    margin: 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
        margin: 3rem;
        margin-bottom: 0;
    }
}

.Form {
    // margin-bottom: 20px;
}

.inputWrapper {
    display: inline-flex;
}

.EmailInput {
    background-color: #0000001f !important;
}

.passwordHint {
    font-size: 11px;
}

.formActions {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 1rem;
    text-align: right;
    position: relative;
    gap: 0.5rem;
}

// .flexGap {
//     --gap: 12px;
//     display: inline-flex;
//     flex-wrap: wrap;
//     margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
//     width: calc(100% + var(--gap));
// }

// .flexGap > * {
//     margin: var(--gap) 0 0 var(--gap);
// }

.form {
    width: 100%;
    // max-width: 700px;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.error {
    border-color: var(--brand-danger-color);
}

.inputFeedback {
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
}

.formFeedback {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
}

.PasswordVisibilityButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 0px;
}

.PasswordVisibilityIcon {
}

.AcceptTerms {
    // display: flex;
    // justify-content: flex-end;
    color: white;
}

.AcceptTermsLink,
.PrivacyLink {
    color: white;

    &:hover {
        color: var(--brand-alert-color);
    }
}

.AccountVerified {
    display: flex;
    align-items: center;
    justify-content: center;
}
