// @import 'scss/globals';

.CustomizationCoverContainer {
    position: relative;
    // margin: 0.75rem;
    margin-bottom: 1rem;
    cursor: pointer;

    &.hover {
        z-index: 1;
    }
    height: 100%;
}

.CustomizationCoverDropshadow {
    position: relative;
    height: 100%;
}

.CustomizationCover {
    z-index: 1;
    padding: 0;
    position: relative;

    transform: translateZ(0);
    will-change: transform;
    height: 100%;
}

.CustomizationCoverDropshadow::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--shadow-color-dark);
    box-shadow: 0px 0px 60px 0px var(--shadow-color-dark);
    opacity: 0;
    transition: all 0.24s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(0.9) translateZ(-100px);
}

.CustomizationCoverDropshadow.hover::after {
    opacity: 1;
}

.Cover {
    // position: absolute;
    // z-index: 1;
    padding: 1rem;
    height: 100%;

    pointer-events: none;

    transition: 0.2s transform cubic-bezier(0.18, 0.89, 0.32, 1.28);

    &.single-card {
        &.hover {
            transform: scale(1.06);
        }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    // :first-child {
    //     flex: 1;
    // }

    label {
        margin-top: 0.5rem;
        font-weight: var(--font-weight-bold);
    }
}
