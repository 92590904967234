.CardsList {
    display: flex;
    flex-direction: column;
    // align-items: center;

    // min-height: 300px;
    // max-height: 400px;

    width: 100%;
    // overflow-y: scroll;
    position: relative;
}

.Item {
    // margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    & > div:first-child,
    div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    &.isEdit {
        flex-direction: row;
        padding: 0.5rem 1rem;
        // padding: 1rem 1.25rem;

        // @media (min-width: 576px) {
        //     padding: 1rem 3rem;
        // }
        // margin-bottom: 1rem;
        // border-radius: var(--box-border-radius);

        &:hover,
        &:active,
        &:focus {
            background-color: var(--element-edit-background-color);

            .ItemHandle {
                opacity: 1;
            }
        }
    }

    &.isReorder {
        height: 50px;
        position: absolute;
        width: 100%;
        // transform: translateX(-50%);
        cursor: move;
        user-select: none;
    }
}

.ItemHandle {
    // width: 10px;
    // background: var(--brand-color-17);
    // border-radius: 2px;
    // margin-right: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    // color: white;

    opacity: 0.4;

    flex: 0 0 auto;
}

.ItemContent {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
}

.ItemStatusIndicator {
    position: absolute;
    left: calc(2.5rem - (30px / 2));

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-color-2);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 8px;

    svg {
        width: 100%;
        height: 100%;
    }
}

.StartCard {
    gap: 0.5rem !important;
}
