.TagFilter {
    :global {
        .react-select {
            &__input-container {
                color: var(--text-color-contrast) !important;
            }

            &__placeholder {
                color: var(--brand-color-5);
            }
        }
    }
}
