// @import 'scss/globals';

.CustomizationFonts {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
}

.GroupList {
    list-style-type: none;

    li:last-child {
        border-bottom: none;
    }
}

.GroupItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);

    :global {
        .icon {
            width: 30px;
            height: 30px;
            color: var(--brand-color-5);
            margin-right: 0.5rem;
        }
    }
}

.GroupName {
    flex: 1;
}

.EditButton {
    padding: 0;

    :global {
        .icon {
            padding: 8px;
            margin-right: 0;
        }
    }

    svg {
        width: 100%;
        height: 100%;
        padding: 0%;
    }
}

.InputWrapper {
    textarea {
        min-height: 400px;
    }
}
