// @import 'scss/globals';
.Settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.Header {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    li {
        display: inline-block;
        margin-bottom: 12px;

        a {
            text-decoration: underline;
            // color: $gray-darker;

            &:hover,
            &:active,
            .my-active {
                color: var(--primary-color);
            }
        }
    }
}
