@import 'scss/globals';

.ManagementAccessCodes {
    display: flex;
    flex-direction: column;

    max-width: 1000px;
    align-self: center;
    width: 100%;

    margin-bottom: 2rem;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

.AccessCodeListHeader {
    display: flex;
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 0.5rem;

    & > h3 {
        color: var(--category-cover-title-color);
        font-weight: var(--font-weight-bold);
        font-size: $h6-font-size;
        line-height: 1.5;
    }
}

.AccessCodeList {
    gap: 0.5rem;
}

.AccessCodeListExpiresAt,
.AccessCodeListTitle {
    align-items: center;
    display: flex;
    flex: 1 1 0px;
}

.AccessCodeListCode,
.AccessCodeListUsers {
    align-items: center;
    display: flex;
    flex: 0.5 1 0px;
}

.AccessCodeListActions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.AccessCodeItem {
    display: flex;
}

.expired {
    color: var(--brand-danger-color);
}
