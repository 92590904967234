@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.ReadProgressBarContainer {
    display: flex;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    width: 100%;
    transition: height 0.3s ease;
    z-index: 9999;
    height: 5px;
    background: var(--brand-color-10);
    overflow: hidden;

    @include media-breakpoint-up(sm) {
        height: 12px;
    }

    &.hidden {
        height: 0px;
    }
}

.Bar {
    position: relative;
    left: 0px;
    background: var(--brand-alert-color);
    top: 0px;
    bottom: 0px;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.1s ease;
    will-change: transform;

    &.completed {
        background: var(--brand-color-4);
    }
}
