.ManagementTags {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

.TagTitle {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .Icon {
        color: var(--button-background-color);
    }
}
