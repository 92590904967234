@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.QuestDetail {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.QuestDetailHeader {
    margin-bottom: 3rem;
}

.GroupDetailContent {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.QuestTitle {
    // margin: 0;
    color: var(--text-color-contrast);
}

.QuestSubtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.QuestCover {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: center;

    @include media-breakpoint-up(md) {
        margin-right: 1.75rem;
    }

    :global {
        .cover {
            margin: 0;
        }
    }
}

.QuestCoverCard {
    padding: 0;
    position: relative;

    min-width: 285px;

    &.in_progress,
    &.ended {
        height: auto;
    }

    // cursor: pointer;

    button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        padding-left: 15px;
        padding-right: 15px;
    }
}

.QuestCoverCard::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px var(--shadow-color-light);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.QuestCoverCard:hover::after {
    opacity: 1;
}

.CoverImage {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.Calendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.CalendarIcon {
    font-size: 2rem;
    margin-right: 1rem;
    color: var(--brand-color-5);
}

.TablePagination {
    border-bottom: none !important;
}
// .QuestDates {
// }

// .StartDate {
// }

// .EndDate {
// }

.DateSeparator {
    margin: 0.5rem;
    color: var(--brand-color-5);
}

.QuestTime {
    display: grid;
    justify-items: center;

    font-size: $h7-font-size;
    margin-bottom: var(--box-item-margin);

    pointer-events: none;

    &.awaiting {
        font-style: italic;
    }

    &.in_progress {
        font-weight: var(--font-weight-bold);
        color: var(--brand-color-5);
    }

    // &.ended {
    // }

    &.weeksLeft {
        color: var(--brand-color-5);
    }

    &.daysLeft {
        color: var(--brand-color-2);
    }

    &.hoursLeft {
        color: var(--brand-color-1);
    }
}

.TaskBar {
    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);

    display: grid;
    align-items: center;
    justify-items: center;

    font-size: $h7-font-size;
    font-weight: var(--font-weight-bold);
    padding: 10px;
    color: var(--text-color-contrast);
    height: 40px;

    pointer-events: none;

    &.joined {
        background: var(--brand-color-5);
    }

    &.open_to_join {
        background: var(--brand-color-15);
    }

    &.request_to_join {
        background: var(--brand-color-2);
    }
}

.ActionButton {
    margin-top: 0.75rem;
    font-size: $h5-font-size;
    font-weight: var(--font-weight-bold);
    color: var(--brand-color-8);
    align-self: center;
}

.TasksListContainer {
    padding: 0 var(--box-padding-small-leftright);
    margin-bottom: var(--box-padding-small-topbottom);

    @include media-breakpoint-up(sm) {
        padding: 0 var(--box-padding-large-leftright);
        margin-bottom: var(--box-padding-large-topbottom);
    }
}

.TasksListHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.TasksListTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h4-font-size;
}

.TasksList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    &.ended {
        filter: grayscale(100%) opacity(60%);
    }
}

.TasksListDone {
    font-size: $h6-font-size;
    text-align: right;
}

// TeamProgress

.TeamProgress {
    margin-bottom: 1rem;
}

.TeamProgressHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.TeamProgressTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.TeamProgressPeopleCompleted {
    font-size: $h6-font-size;
    text-align: right;
}

// Description

.Description {
    margin-bottom: 1rem;
}

.DescriptionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;
}

.DescriptionTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h4-font-size;
}

.Container {
    width: 100%;
}

.Element {
    margin-bottom: 1rem;
}

// Group Progress

.GroupsProgress {
    margin-bottom: 1rem;
}

.GroupsProgressHeader {
    margin-bottom: 0.5rem;
}

.GroupsProgressTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.GroupProgress {
    margin-bottom: 1rem;
}

.GroupHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.GroupLabel {
    margin: 0;
}

.GroupPeopleCompleted {
    font-size: $h6-font-size;
    text-align: right;
}

.MyGroups {
    margin-bottom: 1rem;
}

// .OtherGroups {
// }
.GroupStatistics {
    margin-bottom: 1rem;
}

.Statistics {
    & > div:last-child {
        & > .GroupStatisticContent {
            &:after {
                border: none;
            }
        }
    }
}

.GroupStatistic {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    :global {
        .circle-icon {
            margin-right: 1rem;
        }
    }
}

.GroupStatisticsHeader {
    flex: 1 1 auto;
}

.GroupStatisticContent {
    display: flex;
    width: 100%;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        width: 100%;
        border-bottom: 1px solid var(--brand-color-14);
    }
}

.GroupStatisticsLabel {
    margin-bottom: 1rem;
}

.GroupStatisticsTitle {
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
}

.GroupStatisticsSubtitle {
    font-size: $h8-font-size;
}

.GroupStatisticsValue {
    display: flex;
    align-items: center;

    font-size: 1.75rem;
    font-weight: var(--font-weight-bold);
}

.CardsIcon {
    background-color: var(--brand-color-2) !important;
    color: var(--text-color-contrast);
}

.QuestIcon {
    background-color: var(--brand-color-1) !important;
    color: var(--text-color-contrast);
}

.QuestionsCorrectIcon {
    background-color: var(--brand-color-20) !important;
    color: var(--text-color-contrast);
}

.GroupActionButton {
    // TODO: check new button style
    &.leave {
        --background-color: var(--brand-danger-color);
    }

    &.join {
        --background-color: var(--brand-alert-color);
    }

    &.request {
        --background-color: var(--brand-alert-color);
    }
}

.GroupMembersList {
    display: flex;
    flex-direction: column;

    div:last-child {
        border-bottom: none;
    }
}

.GroupMembers {
    margin-bottom: 1rem;
}

.PotentialMembers {
    margin-bottom: 0;
}

.GroupMembersHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.GroupMembersTitle,
.GroupMembersAmount {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.GroupMembersTitle {
    margin-right: 1rem;
    margin-bottom: 0;
}

.GroupMembersStat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.GroupMembersIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--brand-color-5);
}

.GroupMembersCards {
    font-size: $h6-font-size;
    text-align: right;
    color: var(--brand-color-5);
}

.MemberItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    // border-bottom: 1px solid var(--brand-color-14);
}

.MemberAvatar {
    margin-right: 0.5rem;
    display: flex;

    color: var(--brand-color-5);

    :global {
        .icon {
            width: 30px;
            height: 30px;
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

// .MemberAvatarIcon {
// }

.MemberName,
.MemberCards {
    font-family: var(--header-font-family);
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    color: var(--brand-color-5);
    // color: var(--text-color);
    padding-top: 3px;
}

// Quest Progress

.QuestProgress {
    margin-bottom: 1rem;
}

.QuestProgressHeader {
    margin-bottom: 0.5rem;
}

.QuestProgressTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.QuestProgress {
    margin-bottom: 1rem;
}

.QuestProgressBar {
    display: flex;
    flex-direction: row;
}

.QuestButton {
    margin-left: 0.5rem;

    div:first-child {
        width: 40px;
        height: 40px;
    }
}

.QuestHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.QuestLabel {
    margin: 0;
}

.MyGroups {
    margin-bottom: 1rem;
}

.AddButton {
    padding: 0;
}

.RemoveButton {
    padding: 0;
}

.GroupActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}
