@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.DeckEditDetailForm {
    margin-bottom: 2rem;
}

.InputContainer {
    width: 100%;
}

.InputContent {
    margin-bottom: 1rem;
}

.DeckDetail {
    display: flex;
    gap: 2rem;
}

.PublicationStateLabel {
    margin-top: 1rem;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.75rem;
}

.DeckCoverWrapper {
    // margin-right: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :global {
        .cover {
            margin: 0;
            margin-top: 0.3rem;
        }
    }
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;

    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.formDelete {
    margin-right: 1rem;
}

.DestructiveActions {
    display: flex;
    margin-right: auto;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

////
.Navigation {
    display: flex;
    position: sticky;
    bottom: 25vh;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    width: 100%;
    transform: translateY(-32px);
    height: 0;

    @include media-breakpoint-up(sm) {
        bottom: 33vh;
    }

    pointer-events: none;
}

.NavButton {
    --size: 30px;
    width: var(--size);

    @include media-breakpoint-up(sm) {
        --size: 50px;
    }

    padding-left: 0;
    padding-right: 0;

    &-enabled {
        opacity: 1;
        pointer-events: initial;

        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &-disabled {
        opacity: 0;
        pointer-events: none;

        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    // Extra hit area for the button
    // TODO: no longer works with new buttons. So create new divs for hitarea?
    // &:after {
    //     content: '';
    //     position: absolute;
    //     width: 50px;
    //     height: 75vh;
    // }
}
////

.ArrowIcon {
    @include media-breakpoint-up(sm) {
        font-size: $h3-font-size;
    }
}

.Previous {
    .ArrowIcon {
        margin-right: 3px;
    }
}

.Previous.NavButton-enabled {
    transform: translateX(calc(-100% - 10px));

    @include media-breakpoint-up(sm) {
        transform: translateX(calc(-100% - 12px));
    }
}

.Previous.NavButton-disabled {
    transform: translateX(calc(-100%));
}

.Next.NavButton-enabled {
    transform: translateX(calc(100% + 10px));

    @include media-breakpoint-up(sm) {
        transform: translateX(calc(100% + 12px));
    }
}

.Next.NavButton-disabled {
    transform: translateX(100%);
}

.CardsGroup {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 20px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
        padding: 0;
    }
}

.TransitionGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;

    transform-style: preserve-3d;
}

.perspective-ismobile {
    transform: perspective(3200px);
}

.perspective-isdesktop {
    transform: perspective(800px);
}

.SaveButton {
    width: 100%;
}
