.RoleItem {
    display: flex;
}

.RoleName {
    color: var(--brand-color-5);
    font-size: 1.125rem;
    font-weight: var(--font-weight-bold);
}

.PermissionsList {
    padding-left: 1.5rem !important;
}

.CollapsableListControls {
    display: flex;
    gap: 0.5rem;
}
