// @import 'scss/globals';

.CoverImage {
    width: 100%;
    height: 100%;

    // pointer-events: none;
    transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);

    // @include media-breakpoint-up(sm) {
    //     flex: 0 1 auto;
    // }
}
