.CircleIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: yellowgreen;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        padding: 25%;
    }
}
