@import 'scss/globals';

.CategoryDetail {
    max-width: 1000px;
    align-self: center;
    width: 100%;

    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
}

.CategoryDetailHeader {
    margin-bottom: 3rem;
}

.CategoryTitle {
    // margin: 0;
    color: var(--text-color-contrast);
}

.CategorySubtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.Module {
    margin-bottom: 2rem;
}

.ModuleHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.ModuleTitle {
    // margin: 0;
    color: var(--text-color-contrast);
}

.ModuleSubtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.DisplayControls {
    display: flex;
    justify-content: flex-end;
}
