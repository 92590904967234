.CardActions {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    --background-color: var(--brand-alert-color);
    background: var(--background-color);
    min-height: 50px;

    transition: transform 0.35s cubic-bezier(0.49, 0.8, 0, 1.28);

    &.published {
        --background-color: var(--brand-danger-color);
    }

    &.concept {
        --background-color: var(--brand-alert-color);
    }

    --actionbar-page-padding: var(--page-padding);
    padding: 0.75rem var(--actionbar-page-padding);
}

.LeftActions,
.RightActions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.PublishedStateLabel {
    color: var(--text-color-contrast);
}

.EndpointLabel {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: var(--text-color-contrast);
}

.ExclamationIcon {
    font-size: 1.5rem;
}
