// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Management {
    display: flex;
    flex-direction: column;

    max-width: 1000px;
    align-self: center;
    width: 100%;

    margin-bottom: 2rem;
}

.AccessCodeIcon,
.TagsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;

    .Icon {
        color: white;
    }

    :global {
        .icon {
            width: 70px;
            height: 70px;
        }
    }

    svg {
        margin-right: 0.1rem;
        width: 75%;
        height: 75%;
        padding: 15%;
    }
}

.Header {
    margin-bottom: 1rem;
}

.Content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.Title {
    // margin: 0;
    color: var(--text-color-contrast);
}

.Navigation {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: -0.5rem;
}

.NavLink {
    display: flex;
    gap: 0.5rem;
    text-decoration: none;

    &:global {
        &.active {
            color: var(--brand-color-2);
        }
    }
}

// a.NavLink:hover {
//     color: var(--brand-color-2);
//     text-decoration: none;
// }
