.RedoCircleIcon {
    display: flex;
    background: var(--brand-color-5);
    width: 20px;
    height: 20px;
    border-radius: 50%;

    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
        padding: 3px;
        color: var(--brand-color-12);
        box-sizing: border-box !important;
    }
}
