.TagLabel {
    color: var(--text-color-contrast);
    background-color: var(--button-background-color);
    padding: 1px 14px 1px 10px;
    // border-radius: 25px 0px 0px 25px;
    border-radius: 0px 25px 25px 0px;

    font-size: 0.8rem;
    display: inline;
    max-height: 20px;
    overflow: hidden;
}
