@import 'scss/globals';

.EditModuleContainer {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.Header {
    margin-bottom: 2rem;
}

.Title {
    border-bottom: 1px solid var(--text-color-contrast);
    color: var(--text-color-contrast);
    padding-bottom: 1rem;
}

.Subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--text-color-contrast);
}

.Modified {
    margin-top: 0.3rem;
    font-size: $h7-font-size;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    button:first-child {
        margin-right: auto;
    }
}
