.VideoElement {
    width: 100%;

    &.isReorder {
        display: flex;
        align-items: center;
    }
}

.isFocused {
    // outline: 3px solid red;
}

.Header {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    label {
        flex-grow: 1;
        margin: 0;
    }
}

.FormButtons {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-shrink: 0;
    gap: 1rem;

    button {
        margin: 0;
    }
}

.Loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SourceInput {
    display: flex;
    gap: 1rem;
}

.ElementSave,
.ElementDelete {
    margin: 0;
}
