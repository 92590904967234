.EditDefaultCopy {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ElementSave {
    align-self: flex-end;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
