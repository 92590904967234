// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.FileDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
}

.FileNameWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 0.5rem;

    label {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        margin-right: 1rem;
    }
}

.FileActions {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    @include media-breakpoint-up(sm) {
        justify-content: flex-start;
    }
}

.UploadButton {
    svg {
        margin-right: 0.5rem;
    }
}

.IconButton {
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    flex-shrink: 0;
}

.UploadButton {
    height: 40px;
    flex-shrink: 0;
}
