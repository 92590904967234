.RoleSelect {
    display: flex;
    width: 100%;
}

.MemberListRole {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.RoleForm {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    place-items: center;
    justify-content: space-between;
    width: 100%;
}

.FormControls {
    display: flex;
    gap: 0.5rem;
}

.RoleDropdown {
    width: 100%;
}
