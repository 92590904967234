.DeckResultsTitle {
    color: var(--category-cover-title-color);
}
.DeckResultsSubtitle {
    color: var(--category-cover-title-color);
    font-size: 1rem;
}

.ActionButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.DeckResultEndpoint {
    font-size: 1.5rem;
    font-style: italic;
    display: flex;
    justify-content: center;
    padding: 2rem;
    font-weight: bold;
}

.DeckResultOverviewDescription {
    margin-bottom: 1rem;
}

.PartyImageContainer {
    height: 240px;
    min-height: 140px;
    margin: 1rem 0;
}

.PartyImage {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
}

.ResultContainer {
    max-height: 80vh;
}

.EndCardsContainer {
    overflow-y: scroll;
}
