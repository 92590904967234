@import 'scss/globals';

.ManagementMembersListBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-height: 550px;
}
.ManagementMembers {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
    margin-bottom: 2rem;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

.MembersStat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MembersAmount {
    color: var(--text-color-contrast);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
    padding-top: 3px;
}

.MembersIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--text-color-contrast);
}

.Loader {
    :global {
        .spinner {
            background-color: var(--brand-color-5);
        }
    }
}
