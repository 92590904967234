// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Customization {
    display: flex;
    flex-direction: column;

    max-width: 1000px;
    align-self: center;
    width: 100%;

    margin-bottom: 3rem;
}

.Header {
    margin-bottom: 1rem;
}

.Content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.Title {
    // margin: 0;
    color: var(--text-color-contrast);
}

.Navigation {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 1rem;
}

.NavLink {
    flex: 1 1;

    text-decoration: none;

    &:global {
        &.active {
            color: var(--brand-color-2);
        }
    }
}

a.NavLink:hover {
    color: var(--brand-color-2);
    text-decoration: none;
}

.Icon {
    font-size: 5rem;
    margin: 1rem;
}
