.Option {
    display: flex;
    place-items: center;
    margin-bottom: 1rem;
    cursor: pointer;

    &:hover {
        .OptionNumber {
            background-color: var(--brand-color-8);
            color: var(--brand-color-13);
        }
    }
}

.OptionNumber {
    width: 30px;
    height: 30px;
    font-size: 0.875rem;
    margin-right: 0.5rem;

    @media (min-width: 576px) {
        width: 40px;
        height: 40px;
        font-size: 1rem;
        margin-right: 1rem;
    }

    display: flex;
    place-items: center;
    justify-content: center;
    border: 3px solid var(--brand-color-8);
    border-radius: 50%;
    flex-shrink: 0;
}

.OptionDetails {
    width: 100%;
    pointer-events: none;
}

.OptionText {
    font-size: 0.875rem;

    @media (min-width: 576px) {
        font-size: 1rem;
    }
}

.ProgressBar {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (min-width: 576px) {
        :global {
            .progressbar {
                height: 6px;
            }
        }
    }
}

.OthersPercentage {
    min-width: 40px;
    color: var(--brand-color-4);
    font-weight: var(--font-weight-bold);
}

.selected {
    background-color: var(--brand-color-4);
    border: 3px solid var(--brand-color-4);
    color: var(--brand-color-13);
}

.notselected {
    --background-color: var(--brand-color-9);
    --color: var(--text-color-contrast);
    &:before {
        border: 3px solid var(--brand-color-9);
    }
}

.disabled {
    pointer-events: none;
}
