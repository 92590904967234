// @import 'scss/globals';
$questionmark-background-color: var(--brand-color-1);
$exclamationmark-background-color: var(--brand-color-2);
$checkmark-background-color: var(--brand-color-4);

.CardStatusIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--nav-icon-background-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;

    &.new,
    &.in_progress,
    &.completed {
        padding: 8px;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &.new {
        background-color: $questionmark-background-color;
    }

    &.in_progress {
        background-color: $exclamationmark-background-color;
    }

    &.completed {
        background-color: $checkmark-background-color;
        padding-bottom: 7px;
    }
}
