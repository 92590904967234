.ElementsList {
    display: flex;
    flex-direction: column;
    // align-items: center;

    // min-height: 300px;
    // max-height: 400px;

    width: 100%;
    // overflow-y: scroll;
    position: relative;
}

.Element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    padding: 1rem 1.25rem;

    @media (min-width: 576px) {
        padding: 1rem 3rem;
    }

    & > div:first-child {
        display: flex;
        flex-direction: row;
        // align-items: center;
        gap: 1rem;
    }

    &.isEdit {
        flex-direction: row;

        --box-padding-large-leftright: 3rem;
        padding: var(--box-padding-large-topbottom) var(--box-padding-large-leftright) 2rem;

        margin-bottom: 1rem;
        border-radius: var(--box-border-radius);

        &:hover,
        &:active,
        &:focus {
            background-color: var(--element-edit-background-color);

            .ItemHandle {
                opacity: 1;
            }
        }
    }

    &.isReorder {
        height: 50px;
        position: absolute;
        width: 100%;
        // transform: translateX(-50%);
        cursor: move;
        user-select: none;

        padding: 0.5rem 1rem;
    }
}

.ItemHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;

    flex: 0 0 auto;
}

.ItemContent {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
}

.ElementStatusIndicator {
    position: absolute;
    left: calc(2.5rem - (30px / 2));

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-color-2);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 8px;

    svg {
        width: 100%;
        height: 100%;
    }
}
