@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Card {
    margin: 0;
    padding: 0;
    top: 0;

    &.card-current {
        min-height: calc(var(--card-ratio-portrait) * var(--card-width));
    }

    transition: transform 0.45s cubic-bezier(0.49, 0.8, 0, 1.28);
    transition-property: transform, width;
    transform-origin: center (calc(var(--card-ratio-portrait) * var(--card-width) * 0.9));
    outline: 1px transparent solid; // fix for blurred lines

    // TODO: performance issue
    // will-change: transform;
}

.is-current {
    background-color: transparent !important;
    pointer-events: none;
}

.card-previous {
    left: 0px;
}

.card-current {
    width: 100%;
}

.card-next {
    right: 0px;
}

.card-previous,
.card-next,
.card-other {
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
}

.filter-init {
    filter: grayscale(0) blur(0) opacity(1) brightness(1);
}

.filter-active {
    filter: grayscale(0.3) blur(1px) opacity(1) brightness(1);
}

.Header {
    position: sticky;
    top: -1px;
    z-index: var(--z-index-card-header);

    background-color: var(--box-background-color);
    border-radius: var(--box-border-radius);
    padding: var(--box-padding-small-topbottom) var(--box-padding-small-leftright);

    margin-bottom: 1rem;

    @media (min-width: 576px) {
        padding: var(--box-padding-large-topbottom) var(--box-padding-large-leftright) 1.5rem;
        --box-padding-large-leftright: 3rem;
    }

    @media (min-width: 768px) {
        --box-padding-large-leftright: 3rem;
        padding: var(--box-padding-large-topbottom) var(--box-padding-large-leftright) 2rem;
    }

    &.edit {
        position: initial;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.Content {
    padding: var(--box-padding-small-topbottom) 0;

    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    // @media (min-width: 576px) {
    //     padding: 0 var(--box-padding-large-leftright) var(--box-padding-large-topbottom)
    //         var(--box-padding-large-leftright);
    //     --box-padding-large-leftright: 3rem;
    //     --box-padding-large-topbottom: 2rem;
    // }

    // @media (min-width: 768px) {
    //     --box-padding-large-leftright: 5rem;
    //     --box-padding-large-topbottom: 3rem;
    // }
    // min-height: 100vh;
}

.Subtitle {
    font-size: $h7-font-size;
}

.NextButton {
    align-self: flex-end;
}

.Overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 2;
    border-radius: 10px;

    transition: background-color 0.45s cubic-bezier(0.32, 0, 0.03, 1.15);
}

.TitleInput {
    display: flex;
    gap: 1rem;
}

.CardDelete,
.CardSave {
    margin: 0;
}

.ActionsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}

.TitleContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-between;
}

.NoElements {
}
