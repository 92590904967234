.ManagementCategories {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
}

.CategoriesList {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    div:last-child {
        border-bottom: none;
    }
}

.CategoryItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
    width: 100%;

    flex-direction: row;
    padding: 0.5rem 1rem;

    &:hover,
    &:active,
    &:focus {
        background-color: var(--element-edit-background-color);

        .ItemHandle {
            opacity: 1;
        }
    }

    position: absolute;
    width: 100%;
    cursor: move;
    user-select: none;
}

.ItemHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.4;

    flex: 0 0 auto;
}

.CategoryTitle,
.CategoryActionsPopover {
    flex: 1 1;
}
