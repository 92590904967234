.Home {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.PageHeaderWrapper {
    width: 100%;
    max-width: 1000px;
}

.submitButton {
    margin-top: 24px;
}
