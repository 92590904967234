.QuestContinueActions {
    padding: 0 var(--box-padding-large-leftright);
}

.ContinueButton {
    width: 100%;
    --color: #fff;
    --border-color: transparent;
    --background-color: var(--brand-alert-color, white);

    align-self: center;

    @media (min-width: 768px) {
        min-height: 50px;
    }

    opacity: 1;
    transform: translateY(0);

    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

    &.hidden {
        transform: translateY(30px);
        opacity: 0;
    }
}

.StickyContainer {
    position: fixed;
    bottom: 0;
    left: 0px;
    right: 0px;
    z-index: var(--z-index-quests-actions-bar);
    background: var(--brand-color-2);

    //
    padding: 1rem;
    cursor: pointer;
    color: white;
    text-align: center;
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);

    //

    transition: transform 0.35s cubic-bezier(0.49, 0.8, 0, 1.28);

    transform: translateY(100%);
    &.isSticky {
        transform: translateY(0);
    }

    &.hidden {
        height: 0px;
    }
}
