.DeckEditHeader {
    display: flex;
    flex-direction: column;
}

.Title {
    border-bottom: 1px solid var(--text-color-contrast);
    color: var(--text-color-contrast);
    padding-bottom: 1rem;
}

.Subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--text-color-contrast);
}

.RelatedQuests {
    font-size: 0.875rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    color: var(--brand-color-14);
}
