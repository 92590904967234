.ModuleForm {
    display: flex;
}

.ModuleDetail {
    display: flex;
    margin-bottom: 1rem;
}

.InputContainer {
    width: 100%;
}

.InputContent {
    margin-bottom: 1rem;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;

    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.formDelete {
    margin-right: auto;
}
