.ManagementRoles {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
    gap: 1rem;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

.RolesList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.formActions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: flex;
    flex-wrap: wrap;
}
