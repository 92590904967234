.PersonalProgressBar {
    pointer-events: none;
    width: 100%;
    padding: 0 var(--box-padding-large-leftright);
}

.Label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
