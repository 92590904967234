@use 'sass:math';

@import 'scss/globals';

.Invite {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.InviteForm {
    width: 100%;
}

.Header {
    margin-bottom: 2rem;
}

.Title {
    // margin: 0;
    color: var(--text-color-contrast);
}

.Subtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.FormEmails {
    margin-bottom: 1rem;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.FormFieldHeader {
    margin-bottom: 0.5rem;
}

.FormFieldTitle {
    font-size: $font-size-base * math.div(20, 16);
    font-weight: var(--font-weight-h);
    color: var(--brand-color-5);
}

.FormFieldSubtitle {
    font-size: $h7-font-size;
}

.formActions {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}

.navActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.emailInputWrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.link {
    $margin: 6px;
    margin-right: $margin;
    margin-left: $margin;
}

.error {
    border-color: var(--brand-danger-color);
}

.errorMessage {
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
    flex: 1;
    white-space: pre-wrap;
}
