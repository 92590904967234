.CardNode {
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 40px;
    padding: 0.5rem 1rem;
    background-color: var(--brand-color-5);

    transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';
    box-shadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)';
    border-radius: var(--box-border-radius);

    &.selected {
        box-shadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';
    }
}

.Title {
    color: var(--text-color-contrast);
}

.Handle {
    width: 30px !important;
    height: 30px !important;
    opacity: 1;
    border-radius: 0;
    border: none;
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Handle:hover {
    .HandleVisual {
        width: 20px;
        height: 20px;
    }
}

.Left {
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
}

.Right {
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
}

.HandleVisual {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    border: 3px var(--brand-color-5) solid;
    pointer-events: none;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.StartCardIcon {
    position: absolute;
    top: -20px;
    left: 5px;
    pointer-events: none;
}

.EndCardIcon {
    position: absolute;
    top: -20px;
    right: 5px;
    pointer-events: none;
}
