@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.QuestDetail {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.QuestDetailHeader {
    margin-bottom: 3rem;
}

.QuestDetailContent {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.QuestTitle {
    // margin: 0;
    color: var(--text-color-contrast);
}

.QuestSubtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.QuestCover {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
        margin-right: 1rem;
    }
}

.QuestCoverCard {
    // position: sticky;
    top: var(--page-padding-desktop);

    padding: 0;
    // position: relative;

    min-width: 300px;

    &.in_progress,
    &.ended {
        height: auto;
    }

    // cursor: pointer;

    button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        padding-left: 15px;
        padding-right: 15px;
    }

    // fix for chrome bug border-radius not clipping
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
}

// .QuestCoverCard::after {
//     content: '';
//     border-radius: 5px;
//     position: absolute;
//     z-index: -1;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     box-shadow: 0 5px 15px var(--shadow-color-light);
//     opacity: 0;
//     transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
// }

// .QuestCoverCard:hover::after {
//     opacity: 1;
// }

.CoverImage {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.Calendar {
    padding: 0 var(--box-padding-small-leftright);
    margin-bottom: 1rem;
}

.CalendarIcon {
    font-size: 2rem;
    margin-right: 1rem;
    color: var(--brand-color-5);
}

.CalendarContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CalendarTitle {
    color: var(--brand-color-11);
    font-size: $font-size-base;
}

.QuestDates {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.StartDate,
.EndDate {
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-weight-bold);
    color: var(--brand-color-5);

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
}

.DateSeparator {
    margin: 0.5rem;
    color: var(--brand-color-5);
}

.QuestTime {
    display: grid;
    justify-items: center;

    font-size: $h7-font-size;
    margin-bottom: var(--box-item-margin);

    pointer-events: none;

    &.awaiting {
        font-style: italic;
    }

    &.in_progress {
        font-weight: var(--font-weight-bold);
        color: var(--brand-color-5);
    }

    // &.ended {
    // }

    &.weeksLeft {
        color: var(--brand-color-5);
    }

    &.daysLeft {
        color: var(--brand-color-2);
    }

    &.hoursLeft {
        color: var(--brand-color-1);
    }
}

.TaskBar {
    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);

    display: grid;
    align-items: center;
    justify-items: center;

    font-size: $h7-font-size;
    font-weight: var(--font-weight-bold);
    padding: 10px;
    color: var(--text-color-contrast);

    pointer-events: none;

    &.awaiting {
        background: var(--brand-color-15);
    }

    &.ended {
        background: linear-gradient(180deg, var(--brand-color-2) 0%, var(--brand-color-3) 100%);
    }

    &.weeksLeft {
        background: var(--brand-color-5);
    }

    &.daysLeft {
        background: var(--brand-color-2);
    }

    &.hoursLeft {
        background: var(--brand-color-1);
    }

    &.failed {
        background: var(--brand-color-9);
    }

    &.completed {
        background: var(--brand-color-4);
    }
}

.ActionButton {
    margin-top: 0.75rem;
    font-size: $h5-font-size;
    font-weight: var(--font-weight-bold);
    color: var(--brand-color-8);
    align-self: center;
}

.TasksListContainer {
    padding: 0 var(--box-padding-small-leftright);
    margin-bottom: var(--box-padding-small-topbottom);

    @include media-breakpoint-up(sm) {
        padding: 0 var(--box-padding-large-leftright);
        margin-bottom: var(--box-padding-large-topbottom);
    }
}

.TasksListHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.TasksListTitle {
    color: var(--brand-color-11);
    font-weight: var(--font-weight-bold);
    font-size: $font-size-base;
}

.TasksList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    &.ended {
        filter: grayscale(100%) opacity(60%);
    }
}

.TasksListDone {
    font-size: $h6-font-size;
    text-align: right;
}

// TeamProgress

.TeamProgress {
    margin-bottom: 1rem;
}

.TeamProgressHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.TeamProgressTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.TeamProgressPeopleCompleted {
    font-size: $h6-font-size;
    text-align: right;
}

.TeamProgressBar {
    display: flex;
    align-items: center;

    :global {
        .btn {
            margin-left: 0.5rem;
        }
    }
}

// Description

.Description {
    margin-bottom: 1rem;
}

.DescriptionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;
}

.DescriptionTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h4-font-size;
}

.Container {
    width: 100%;
}

.Element {
    margin-bottom: 1rem;
}

// Group Progress

.GroupsProgress {
    margin-bottom: 1rem;

    overflow: hidden;
    z-index: 1;
    position: relative;
}

.isTeamProgressOpen {
    max-height: 100%;
    transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.isTeamProgressClosed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.GroupsProgressHeader {
    margin-bottom: 0.5rem;
}

.GroupsProgressTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.GroupProgress {
    margin-bottom: 1rem;
}

.GroupProgressBar {
    display: flex;
    flex-direction: row;
}

.GroupsButton {
    margin-left: 0.5rem;

    div:first-child {
        width: 40px;
        height: 40px;
    }
    svg {
        margin-right: 0.1rem;
    }
}

.GroupHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.GroupLabel {
    margin: 0;
    font-weight: normal;
    color: var(--brand-color-11);
}

.GroupPeopleCompleted {
    font-size: $h6-font-size;
    text-align: right;
}

.MyGroups {
    margin-bottom: 1rem;
}

//

.CategoryForm {
    display: flex;
}

.QuestDetail {
    display: flex;
    gap: 1rem;
}

.CategoryCover {
    margin-right: 1.5rem;
}

.InputContainer {
    width: 100%;
}

.InputContent {
    margin-bottom: 1rem;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}

.formDelete {
    margin-right: auto;
}

.EditButton {
    --size: 30px;
}

.DateRange {
    display: flex;

    & > div {
        flex: 1 1 auto;

        &:first-child {
            margin-right: 1rem;
        }
    }
}

.EditorLabel {
    transform: translate(14px, -6px) scale(0.75);
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    transform-origin: top left;
    display: block;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-weight: var(--header-font-weight);
    font-family: var(--header-font-family);
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 0.25rem;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.EditorFieldSet {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0.23);

    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-right-color: currentcolor;
    border-right-style: none;
    border-right-width: 0px;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-left-color: currentcolor;
    border-left-style: none;
    border-left-width: 0px;
}
