.TextElement {
    width: 100%;

    // Always full width image for now
    img {
        width: 100% !important;
        height: auto;
    }

    padding: 0 1.5rem;

    @media (min-width: 576px) {
        padding: 0 5rem;
    }

    &.isEdit {
        padding: 0;
    }

    &.isReorder {
        display: flex;
        align-items: center;
    }
}

.isFocused {
    // outline: 3px solid red;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FormButtons {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px; // Temp. fix for overflow hidden buttons, while there is no overflow hidden set?

    flex-shrink: 0;
    gap: 1rem;

    button {
        margin: 0;
    }
}

.Loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
