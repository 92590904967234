@import 'scss/globals';

.HCErrorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 100vh;

    flex: 1;
    padding: 0;
    margin: 0;

    text-align: center;
    color: var(--text-color-contrast);

    background: var(--page-background-gradient);

    h1 {
        line-height: 1.2;
        margin-bottom: 1rem;
    }
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.Hostname {
}

.HCButton {
    margin-top: 1rem;
}

.LogoImage {
    margin: 2rem;
    margin-bottom: 4rem;
    height: 60px;

    @media (min-width: 576px) {
        height: 100px;
    }
}
