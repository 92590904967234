.perspective-ismobile {
    transform: perspective(3200px);
}

.perspective-isdesktop {
    transform: perspective(800px);
}

.TransitionGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;

    transform-style: preserve-3d;
}
