// @import 'scss/globals';

.GroupsDetail {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.GroupsHeader {
    margin-bottom: 3rem;
}

.GroupsTitle {
    color: var(--text-color-contrast);
}

.GroupList {
}
