.PDFElement {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;

    padding: 0 1.5rem;

    @media (min-width: 576px) {
        padding: 0 5rem;
    }

    &.isEdit {
        padding: 0;
    }

    img {
        width: 100%;
        margin: 1rem 0;
    }

    &.isReorder {
        display: flex;
        align-items: center;
    }

    form {
        flex-grow: 1;
        gap: 1rem;
    }
}

.isFocused {
    // outline: 3px solid red;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    label {
        flex-grow: 1;
        margin: 0;
    }
}

.FormButtons {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-shrink: 0;
    gap: 1rem;

    button {
        margin: 0;
    }
}

.Loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SourceInput {
    display: flex;
    gap: 1rem;
}

.ElementSave,
.ElementDelete {
    margin: 0;
}

.MissingImage {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 50px;
    }
}
