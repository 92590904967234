@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.ProfileDetail {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.ProfileDetailHeader {
    margin-bottom: 3rem;
}

.ProfileTitle {
    // margin: 0;
    color: var(--text-color-contrast);
}

.ProfileSubtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.GroupList {
    display: flex;
    flex-wrap: wrap;
}

.Container {
    width: 100%;
}
.FlexContainer {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.Cover {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}
.Content {
    display: flex;
}
.Groups {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.75rem;

    @include media-breakpoint-up(sm) {
        align-items: flex-start;
        justify-content: flex-start;
        // margin: -1rem;
        // gap: 2rem;
    }
    @include media-breakpoint-up(lg) {
        // margin: -1.5rem;
        // gap: 3rem;
    }
}

//

.Element {
    // margin-bottom: calc(5px + 1rem);
}

.ElementHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1rem;
}

.ElementTitle {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h4-font-size;
}

.ElementContent {
    margin-bottom: 2rem;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

// .GridContainer {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;
//     grid-template-rows: repeat(2, 1fr);
//     gap: 2em 2em;
//     grid-template-areas:
//         'Cover Content Content'
//         'Groups Groups Groups';
// }
// .Cover {
//     grid-area: Cover;
// }
// .Content {
//     grid-area: Content;
// }
// .Groups {
//     grid-area: Groups;
// }

.UserStatistics {
    & > div:last-child {
        & > .UserStatisticContent {
            &:after {
                border: none;
            }
        }
    }
}

.UserStatistic {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    :global {
        .circle-icon {
            margin-right: 1rem;
        }
    }
}

.UserStatisticsHeader {
    flex: 1 1 auto;
}

.UserStatisticContent {
    display: flex;
    width: 100%;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        width: 100%;
        border-bottom: 1px solid var(--brand-color-14);
    }
}

.UserStatisticsTitle {
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
}

.UserStatisticsSubtitle {
    font-size: $h7-font-size;
}

.UserStatisticsValue {
    display: flex;
    align-items: center;

    font-size: 1.75rem;
    font-weight: var(--font-weight-bold);
}

.CardsIcon {
    background-color: var(--brand-color-2) !important;
    color: var(--text-color-contrast);
}

.QuestIcon {
    background-color: var(--brand-danger-color) !important;
    color: var(--text-color-contrast);
}

.QuestionsCorrectIcon {
    background-color: var(--brand-color-20) !important;
    color: var(--text-color-contrast);
}
