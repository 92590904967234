// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.QuestDetailContent {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.GroupCover {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
        margin-right: 1.5rem;
    }

    & > div:first-child {
        margin: 0;
    }
}

// .QuestDates {
// }

// .StartDate {
// }

// .EndDate {
// }

// .OtherGroups {
// }

.ListContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.GroupMembersList {
    display: flex;
    flex-direction: column;

    div:last-child {
        border-bottom: none;
    }
}

.GroupMembers {
    margin-bottom: 1rem;
}

.PotentialMembers {
    margin-bottom: 0;
}

.GroupMembersHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.5rem;
}

.GroupMembersTitle,
.GroupMembersAmount {
    color: var(--category-cover-title-color);
    font-weight: var(--font-weight-bold);
    font-size: $h5-font-size;
}

.GroupMembersTitle {
    margin-right: 1rem;
}

.GroupMembersStat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.GroupMembersIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--brand-color-5);
}

.GroupMembersCards {
    font-size: $h6-font-size;
    text-align: right;
    color: var(--brand-color-5);
}

.MemberItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
}

.MemberAvatar {
    margin-right: 0.5rem;
    display: flex;

    color: var(--brand-color-5);

    :global {
        .icon {
            width: 30px;
            height: 30px;
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.MemberName,
.MemberCards {
    font-family: var(--header-font-family);
    font-size: $h6-font-size;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    color: var(--brand-color-5);
    // color: var(--text-color);
    padding-top: 3px;
}
.InputContent {
    display: flex;
    margin-bottom: 1rem;
}

.InputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;

    // Divider
    border-top: 1px solid white;
    padding-top: 1rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.formDelete {
    margin-right: auto;
}

.InputWrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    &.saved {
        textarea {
            border-color: var(--brand-color-4);
        }
    }
}

.Hint {
    font-size: 11px;
}

textarea {
    height: 100px !important;
    resize: none;
}

.EditButton {
    --size: 30px;
}

.TablePagination {
    border-bottom: none !important;
}
