.CardProgressBar {
    margin-bottom: var(--box-item-margin);
    pointer-events: none;
    width: 100%;
}

.ProgressLabel {
    font-size: 0.813rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
