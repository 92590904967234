@import 'scss/globals';

.EditCategoryContainer {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.Header {
    margin-bottom: 2rem;
}

.Title {
    border-bottom: 1px solid var(--text-color-contrast);
    color: var(--text-color-contrast);
    padding-bottom: 1rem;
}

.Subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--text-color-contrast);
}

.Modified {
    margin-top: 0.3rem;
    font-size: $h7-font-size;
}

.TopContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
    margin-bottom: 1rem;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}

.Background {
    opacity: 0.5;
}

.OtherSettings {
    opacity: 0.5;
}
