.MemberListName,
.MemberListGroup {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    svg {
        color: var(--brand-color-5);
    }
}

.MemberListRole {
    align-items: center;
    display: flex;
    flex: 1 1 0px;
    justify-content: space-between;
    margin-right: 1rem;
}

.MemberListDateAdded {
    display: flex;
    align-items: center;
    width: 180px;
}

.MemberControls {
    position: relative;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
}

.Dropdown {
    width: 135px;
}

.ListItemButton {
    text-decoration: underline;
    font-size: 1rem;
    border: none;

    &:focus {
        --hover-outline-offset: 0px;
    }
}

.InactiveMember {
    opacity: 0.5;
}

.Disabled {
    font-style: italic;
}
