@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.DeckStats {
    color: white;
}

.Deck {
    align-self: center;
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.DeckHeader {
    margin-bottom: 3rem;
}

.DeckTitle {
    color: var(--text-color-contrast);
}

.DeckSubtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.CompleteDeckButton {
    align-self: flex-end;
}

//

.Navigation {
    display: flex;
    flex-direction: column;
    position: sticky;
    bottom: 25vh;
    z-index: var(--z-index-deck-actions-bar);
    width: 100%;
    transform: translateY(-32px);
    height: 0;

    @include media-breakpoint-up(sm) {
        bottom: 33vh;
    }

    pointer-events: none;
}

.NavButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NavButton {
    --size: 30px;
    width: var(--size);

    @include media-breakpoint-up(sm) {
        --size: 50px;
    }

    padding-left: 0;
    padding-right: 0;

    &-enabled {
        opacity: 1;
        pointer-events: initial;

        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &-disabled {
        opacity: 0;
        pointer-events: none;

        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    // Extra hit area for the button
    // TODO: no longer works with new buttons. So create new divs for hitarea?
    // &:after {
    //     content: '';
    //     position: absolute;
    //     width: 50px;
    //     height: 75vh;
    // }
}

.ArrowIcon {
    @include media-breakpoint-up(sm) {
        font-size: $h3-font-size;
    }
}

.Previous {
    .ArrowIcon {
        margin-right: 3px;
    }
}

.Previous.NavButton-enabled {
    transform: translateX(calc(-100% - 2px));

    @include media-breakpoint-up(sm) {
        transform: translateX(calc(-100% - 12px));
    }
}

.Previous.NavButton-disabled {
    transform: translateX(calc(-100%));
}

.Next.NavButton-enabled {
    transform: translateX(calc(100% + 2px));

    @include media-breakpoint-up(sm) {
        transform: translateX(calc(100% + 12px));
    }
}

.Next.NavButton-disabled {
    transform: translateX(100%);
}

.CardsGroup {
    display: flex;
    flex-direction: column;
    position: relative;
    // padding: 0 20px;
    gap: 1rem;
    // margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
        padding: 0;
    }

    // touch-action: none;
}

.TransitionGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;

    transform-style: preserve-3d;
}

.perspective-ismobile {
    transform: perspective(3200px);
}

.perspective-isdesktop {
    transform: perspective(800px);
}

.DeckLabel,
.QuestLabel {
    color: var(--brand-color-11);
    font-weight: 700;
    margin-bottom: 0;
}

.ActionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.ForgottenInputIndicator {
    position: absolute;
    top: 0;
    right: 0;

    --size: 15px;
    width: var(--size);
    height: var(--size);
    background: var(--brand-danger-color);
    border-radius: 50%;
}

.ReportButton {
    flex-shrink: 0;
}

.TagsList {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}
