.PublicationStateLabel {
    font-size: 0.7rem;
    padding: 0px 5px;
    border-radius: 3px;
    margin: auto 0px;
    color: var(--brand-color-12);
    pointer-events: none;

    &.concept {
        background-color: var(--brand-alert-color);
    }

    &.published {
        background-color: var(--brand-danger-color);
    }

    &.inline {
        display: inline;
        margin-left: 0.5rem;
    }
}
