@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.PersonalProgressBar {
    margin-bottom: var(--box-item-margin);
    pointer-events: none;

    padding: 0 var(--box-padding-small-leftright);

    @include media-breakpoint-up(sm) {
        padding: 0 var(--box-padding-large-leftright);
    }
}

.Label {
    font-size: 0.813rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
