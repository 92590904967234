.Timer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    font-size: 1.3rem;

    &.running {
        color: var(--brand-danger-color);
    }

    &.savedTimer {
        opacity: 0.7;
    }
}
