// @import 'scss/globals';

.TaskItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--box-padding-large-leftright);
    border: 2px dashed transparent;
    width: 100%;
    gap: 0.5rem;

    div:first-child {
        width: 100%;
    }

    margin-bottom: 0.5rem;

    label {
        font-size: 1rem;
    }

    &.toContinue {
        padding: 10px var(--box-padding-large-leftright);
        color: var(--text-color);

        label {
            color: var(--text-color);
        }

        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: var(--brand-alert-color);

            opacity: 0.5;

            transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    &.noContinue {
        .LabelAndState,
        .TaskProgress {
            opacity: 0.5;
        }
    }

    &:hover {
        &.noContinue {
            &.concept {
                .LabelAndState,
                .TaskProgress {
                    opacity: 0.75;
                }
            }
        }
    }

    &.clickable {
        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
            .TaskLabel {
                .TaskTitle {
                    text-decoration: underline;
                }
                cursor: pointer;
                filter: opacity(1);
            }
        }
    }
}

.TaskProgress {
    display: flex;
    flex-shrink: 0;
}

.LabelAndState {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TaskLabel {
    // color: var(--brand-color-11);
    // font-size: $font-size-base;
    font-weight: 300;
    // font-family: $font-family-base;
    margin-bottom: 0;

    &.completed {
        color: var(--brand-color-4);
    }
}

.LabelIcon {
    margin-right: 0.5rem;
}

.TaskValue {
    white-space: nowrap;
}

.ProgressBarWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.ArrowIcon {
    --size: 36px;
    flex-shrink: 0;
    margin: 0;
}

.CheckMarkIcon {
    color: var(--brand-color-4);
    font-size: 1.2rem;
}

.LabelAndBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.TaskItemContainer {
    display: flex;
}

.HoverLabel {
    position: absolute;
    right: 0;
}
