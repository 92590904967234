@use 'sass:math';

@import 'scss/globals';

.GroupSelect {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    flex: 1 0;

    &.error {
        margin-bottom: 0.2rem;
    }
}

.error {
    border-color: var(--brand-danger-color);
}

.errorMessage {
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
    flex: 1;
}

.BelowField {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FormFieldHeader {
    margin-bottom: 0.5rem;
}

.FormFieldTitle {
    font-size: $font-size-base * math.div(20, 16);
    font-weight: var(--font-weight-bold);
    color: var(--brand-color-5);
}

.FormFieldSubtitle {
    font-size: $h7-font-size;
}
