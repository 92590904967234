.ElementTypeIndicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    svg {
        font-size: 2rem;
    }

    * {
        margin-bottom: 0;
    }
}
