.EdgePath {
    fill: none !important;
    pointer-events: none !important;
    stroke: rgb(255, 0, 0) !important;
    stroke-width: 4 !important;
}
.EdgePathMarker {
    fill: none !important;
    // stroke: transparent !important;
    pointer-events: none !important;
    stroke-width: 1.5 !important;
}
