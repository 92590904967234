// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.TimerBar {
    height: 12px;
    width: 100%;
}

.Bar {
    height: 100%;
    background: var(--brand-color-10);
    border-radius: 28px;
    border: 0px solid var(--brand-color-10);
    overflow: hidden;
    position: relative;
    mask-image: radial-gradient(circle, white, black); // Fix for jagged edge

    transition: all 0.15s ease;

    &.hover {
        width: calc(100% + 0.5rem) !important;
    }
}

.Fill {
    position: absolute;
    left: -100%;
    height: 100%;
    width: 100%;
    background: var(--brand-color-2);
    border-radius: inherit;
    text-align: right;

    &.in_progress {
        background: var(--brand-color-2);
    }

    &.ended {
        background: var(--brand-color-9);
    }

    &.completed {
        background: var(--brand-color-4);
    }

    &.failed {
        background: var(--brand-color-9);
    }

    // &.hover {
    //     left: 0 !important;
    //     background: white !important;
    // }
}

.Outline {
    box-shadow: 3px 0px 0px 3px white;
}

.Label {
    color: var(--text-color-contrast);
    font-size: 1rem;

    transition: all 0.15s ease;

    &.hover {
        // color: var(--text-color);
        margin-left: 0.5rem;
    }
}

.Content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 var(--box-padding-small-leftright);

    @include media-breakpoint-up(sm) {
        padding: 0 var(--box-padding-large-leftright);
    }
}

.Saved {
    opacity: 0.5;
}
