.DecksDialog {
    display: flex;
}

.DeckItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
}

.AddButton {
    padding: 0;
}

.EditButton {
    --size: 30px;
}

.DecksIcon {
    margin-right: 0.5rem;
    display: flex;
    color: var(--brand-color-5);
}

.DialogTitle {
    display: flex;
    justify-content: space-between;
}

.DecksTitle {
    margin-bottom: 0;
}

.ModalDecksList {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 1rem;

    div:last-child {
        border-bottom: none;
    }
}

.DeckDetails {
    display: flex;
    gap: 0.5rem;
}
