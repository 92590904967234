.CustomEdge {
}

.Arrow {
    display: flex;
    align-items: center;
}

.EdgePathSelector {
    fill: none !important;
    stroke: transparent !important;
    stroke-width: 47 !important;
    stroke-dasharray: initial !important;
    animation: initial !important;
}
.EdgePath {
    pointer-events: none !important;

    &.hover,
    &.selected {
        stroke: rgb(255, 0, 0) !important;
        stroke-width: 4 !important;
        cursor: pointer !important;
    }
}
.EdgePathMarker {
    fill: none !important;
    stroke: transparent !important;
    pointer-events: none !important;
}

.ForeignObjectContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DeleteEdgeButton {
    --size: 30px;
}
