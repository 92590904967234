// @import 'scss/globals';

.NavigationIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: currentColor; // var(--nav-icon-background-color);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 8px;

    // transition: transform 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 0.35s cubic-bezier(0.19, 1, 0.22, 1);

    transform: rotate(0);
}

.isSelected {
    transform: rotate(45deg);
}
