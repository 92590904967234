.DeckEditDetailFormik,
.DeckEditDetailForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.formActions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: flex;
    flex-wrap: wrap;
}

.QuestLink {
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
}
