// @import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.Footer {
    width: 100%;
    font-size: 12px;
    margin-top: 3rem;

    img {
        width: 100%;
    }
}

.CurvedClipPath {
    clip-path: url(#footerCurve);
}

.BackgroundMobileImage {
    height: 240px; // fixed height for Safari on mobile, why is 100% not working?
    background-position: bottom;
    background-size: cover;
}

.BackgroundDesktopImage {
}

.Title {
    color: var(--brand-color-3);
    margin-bottom: 1rem;
    text-align: center;
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 24px;
    background-color: var(--brand-color-19);
    color: var(--text-color-contrast);
}

.SubContent {
    display: flex;
    flex-direction: column;

    width: 100%;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.SubContentFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 0.5rem 2rem;
    flex: 1;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.SubContentLeft {
    @include media-breakpoint-up(sm) {
        align-self: flex-end;
    }
}

.SubContentRight {
    @include media-breakpoint-up(sm) {
        align-self: flex-start;
    }
}

.Divider {
    background-color: var(--brand-color-3);
    width: 1px;
}

a {
    color: var(--brand-color-5);
    font-size: 1rem;

    &.alt {
        color: var(--brand-color-14);
    }
}
