.TagEditDetailForm {
    display: flex;
    gap: 0.5rem;
}

.formActions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}
