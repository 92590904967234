.CardFlowWIP {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
}

.DialogTitle {
    display: flex;
    justify-content: space-between;
}

.DialogContent {
    padding: 0.5rem;
}
