@import 'scss/globals';

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.OpenContent {
    .title {
        color: var(--text-color-contrast);
        margin-bottom: 0;
    }
}

.CategoriesList {
    display: flex;
    flex-direction: column;
}

.CategoryItem {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.CategoryTitle {
    color: var(--text-color-contrast);
    font-size: 1.5rem;
    border-bottom: 1px solid var(--text-color-contrast);
}

.CategoryDescription {
    color: var(--text-color-contrast);
    font-weight: var(--bs-body-font-weight);
    font-size: 0.875rem;
}

.DecksList {
    display: flex;
    flex-direction: row;
    gap: 3.1rem;
    flex-wrap: wrap;
}

.LibraryItems {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
