// @import 'scss/globals';
.ManagementLibrary {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
}

// .DeckControls {
//     display: flex;
//     gap: 0.5rem;
// }

.DeckDetails,
.DeckControls,
.DeckTags,
.DeckCategories {
    display: flex !important;
    gap: 0.5rem;
    max-width: 400px;
}

.DeckTags {
    flex-wrap: wrap;
    max-width: 300px;
}

.DeckCategories {
    flex-direction: column;
    min-width: 200px;
}

// .DeckItem {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//     border-bottom: 1px solid var(--brand-color-14);
//     place-items: center;
// }

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}
