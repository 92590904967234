@import 'scss/globals';

.NavButton {
    --size: 40px;

    @media (min-width: 576px) {
        --size: 50px;
    }

    width: var(--size);
    margin: 0;
    padding: 0;
    // font-size: 1rem;

    &.Selected {
        --button-background-color: var(--brand-color-2);
    }
}

.Label {
    position: absolute;
    top: calc(var(--size) + 5px);
    color: var(--color);
    font-size: $h8-font-size;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
}
