// @import 'scss/globals';

.ManagementGroups {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
}

.GroupName {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    svg {
        color: var(--brand-color-5);
    }
}

.GroupActions {
    display: flex;
    justify-content: flex-end;
}
