.InputCheckbox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.Input {
    display: flex;
    gap: 0.5rem;

    input {
        margin-right: 5px;
    }
    input,
    label {
        cursor: pointer;
    }
}

.error {
    border-color: var(--brand-danger-color);
}

.errorMessage {
    color: var(--brand-danger-color);
    margin-top: 2px;
    font-size: 12px;
    flex: 1;
}

.BelowField {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
