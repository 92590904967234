.QuestItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--brand-color-14);
    width: 100%;

    flex-direction: row;
    padding: 0.5rem 1rem;

    &:hover,
    &:active,
    &:focus {
        background-color: var(--element-edit-background-color);

        .ItemHandle {
            opacity: 1;
        }
    }

    position: absolute;
    width: 100%;
    cursor: move;
    user-select: none;
}

.QuestDetails {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.QuestTitle {
    display: flex;
    gap: 0.5rem;
    flex: 1 1;
}

.QuestPeriod {
    flex: 1 1;
}

.QuestIcon {
    display: flex;
    margin: auto 0;
    color: var(--brand-color-5);
}

.QuestControls {
    display: flex;
    gap: 0.5rem;
}

.ItemHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.4;

    flex: 0 0 auto;
}
