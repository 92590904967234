.CharactersLeft {
    margin-top: 2px;
    font-size: 12px;
    text-align: right;
    flex: 1;

    &.hasExceeded {
        color: var(--brand-danger-color);
    }
}
