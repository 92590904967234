.ManagementQuests {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-self: center;
}

.ManagementQuestList {
    gap: 0.5rem;
}

.Header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-right: 1rem;
}

.QuestList {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    div:last-child {
        border-bottom: none;
    }
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}
