.NewCardDialog {
    display: flex;
    flex-direction: column;
}

.Content {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
}

.DialogTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    top: 0;
    z-index: 100;
}

.CardActions {
    position: sticky;
    bottom: 0;
    padding: 0 !important;
    z-index: 100;
}

.CardName {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
