@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.ProfileCover {
    display: flex;
    flex-direction: column;
    // margin-bottom: 1rem;
}

.ProfileCoverCard {
    padding: 0;
    position: relative;
    will-change: transform;
}

.ProfileCoverCard::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px var(--shadow-color-light);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ProfileCoverCard:hover::after {
    opacity: 1;
}

.HitArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    // pointer-events: none;
}

.CoverImage {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
        width: 100px;
        height: 100px;
    }

    @include media-breakpoint-up(lg) {
        width: 170px;
        height: 170px;
    }
}

.EditButton {
    z-index: 1;
}

.RoleBar {
    display: grid;
    align-items: center;
    justify-items: center;

    font-size: $h7-font-size;
    font-weight: var(--font-weight-bold);
    padding: 10px;
    color: var(--text-color-contrast);

    pointer-events: none;

    background-color: var(--brand-color-2);
}

.TopCardSection {
    border-top-left-radius: var(--box-border-radius);
    border-top-right-radius: var(--box-border-radius);
}

.CardsLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    font-size: $h6-font-size;
    padding: 0.5rem;

    &.new {
        background: var(--brand-color-1);
        color: var(--text-color-contrast);
    }

    &.inprogress {
        color: var(--text-color);
    }

    &.completed {
        background: var(--brand-color-4);
        color: var(--text-color-contrast);
    }
}

.CardsIcon {
    border: solid 4px var(--brand-color-5);
    border-radius: 8px;
    padding: 0.1rem 0.75rem;
    margin-right: 0.75rem;

    color: var(--brand-color-5);
    font-size: $h7-font-size;
    font-weight: var(--font-weight-bold);
    line-height: 1;

    &.new {
        border: solid 4px var(--brand-color-1);
        color: var(--brand-color-1);
    }
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    height: 100%;

    padding: 24px;

    @include media-breakpoint-up(sm) {
        padding: 14px;
    }

    @include media-breakpoint-up(lg) {
        padding: 24px;
    }
}

.Name {
    pointer-events: none;
    line-height: 1.2;
}

.JobTitle {
    font-size: $h7-font-size;
    pointer-events: none;
    margin-bottom: 0.5rem;
}
