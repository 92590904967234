// @import 'globals';
// @import '../node_modules/bootstrap/scss/bootstrap';
@import '@yaireo/tagify/src/tagify.scss'; // Tagify CSS

.flavour-theme {
    --primary-color: #007bff;
    --disabled-color: #6c757d;
}

.other-domain-theme {
    --primary-color: #000000;
    --disabled-color: #4a4a4b;
}

// html {
//     overflow-x: hidden;
// }

#app {
    position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    width: 100%;
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: black;
}

/**
***     Typography
*/

body {
    font-family: var(--body-font-family);
    color: var(--text-color);
    // overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: var(--header-font-family);
    font-weight: var(--header-font-weight);
    letter-spacing: 0.05rem;
    font-style: normal;
    // line-height: normal;
    line-height: 1.3;
    margin-bottom: 0.25rem;
}

a {
    color: var(--link-color);
    font-size: var(--link-font-size);
    text-decoration: underline;

    //
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    //

    &.alt {
        color: var(--link-color--alt);
    }
}

input,
textarea {
    &.form-control {
        background-color: var(--input-background-color);
    }
}

label {
    // color: var(--brand-color-5);
    font-size: 1.125rem;
    font-weight: var(--font-weight-bold);
    margin-bottom: 0.25rem;
    font-family: var(--header-font-family);
}

.input-sizer {
    display: inline-grid;
    align-items: stretch;

    &::after,
    textarea {
        width: auto;
        min-width: 1em;
        grid-area: 2 / 1;
        font: inherit;
        padding: 0.25em;
        margin: 0;
        resize: none;
        background: none;
        appearance: none;
        // border: none;
    }

    &::after {
        content: attr(data-value) ' ';
        visibility: hidden;
        white-space: pre-wrap;
    }
}

// .rfs-title {
//     @include font-size(1.5rem);
// }

.page-layout {
    display: flex;
    flex-direction: column;

    flex: 1;

    height: 100%;
    color: var(--text-color);
    padding: 24px;

    width: 100%;
    max-width: 1200px;

    @include media-breakpoint-up(sm) {
        width: 576px;
    }
    @include media-breakpoint-up(md) {
        width: 768px;
    }
    @include media-breakpoint-up(lg) {
        width: 992px;
    }
    @include media-breakpoint-up(xl) {
        width: 1200px;
    }
}

.btn {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:focus,
    &.focus {
        box-shadow: none;
        // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}

button {
    margin: 0;
}

.nav-button {
    &.selected {
        .circle-icon {
            background-color: var(--brand-color-6) !important;
        }
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
        .circle-icon {
            background-color: var(--brand-color-6) !important;
        }
    }
}

.ListItem {
    &.link {
        margin: 0 20px;
    }
}

.btn-flavour {
    --radius: 25px;
    --size: 40px;
    --width: var(--size);
    --height: var(--size);

    border-radius: var(--radius, 25px);

    --top: 2px;
    --bottom: 2px;
    --left: 25px;
    --right: 25px;

    padding-top: calc(var(--top, 2px) + var(--font-offset-top));
    padding-bottom: var(--bottom, -2px);
    padding-left: var(--left, 25px);
    padding-right: var(--right, 25px);

    --color: var(--button-color, #000);
    --border-color: var(--button-border-color, transparent);
    --background-color: var(--button-background-color, white);
    --hover-border-color: var(--button-hover-border-color, white);

    color: var(--color);
    background-color: var(--background-color);

    --font-family: var(--button-font-family);
    --font-size: var(--button-font-size);
    --font-weight: var(--button-font-weight);

    font-family: var(--font-family);
    font-size: var(--button-font-size);
    font-weight: var(--font-weight);

    // reset some button stuff
    height: var(--height);
    border: none;
    outline: none;

    white-space: nowrap;
    text-transform: lowercase;

    position: relative;
    z-index: 0;

    --hover-outline-color: var(--background-color);
    --hover-outline-width: 0px;
    --hover-outline-offset: 4px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: -1;
        border-radius: var(--radius, 25px);
        background-color: var(--background-color);
        width: calc(100% - 2px);
        height: calc(100% - 2px);

        box-shadow: 0px 0px 0px var(--hover-outline-width) var(--hover-outline-color);
        // outline: var(--hover-outline-width) solid var(--hover-outline-color);
        // outline-offset: -1px;
        // will-change: outline;

        transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: var(--radius, 25px);
        background-color: var(--background-color);
        width: 100%;
        height: 100%;
    }

    &:focus,
    &.focus {
        &:before {
            box-shadow: 0 0 0 var(--hover-outline-width) var(--hover-outline-color),
                0 0 0 calc(var(--hover-outline-offset)) rgba(81, 183, 238, 0.5);
        }
    }

    &:hover {
        color: var(--color, #000);
        --hover-outline-width: 4px;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        pointer-events: none;
        --hover-outline-width: 0px;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        --hover-outline-width: 0px;
    }
}

.btn-flavour--alt {
    --color: var(--button-alt-color, #000);
    --border-color: var(--button-alt-border-color, transparent);
    --background-color: var(--button-alt-background-color, white);
    --hover-outline-color: var(--button-alt-hover-border-color, white);
    // --hover-border-color: var(--button-alt-hover-border-color, white);
}

.btn-flavour--border,
.btn-flavour--alt-border {
    background-color: transparent;

    --color: var(--brand-color-5);

    --hover-outline-width: 3px;
    // --hover-outline-color: var(--button-alt-hover-border-color);
    --hover-outline-offset: 6px;

    &:before {
        top: calc(var(--hover-outline-offset) * 0.5);
        left: calc(var(--hover-outline-offset) * 0.5);
        width: calc(100% - var(--hover-outline-offset));
        height: calc(100% - var(--hover-outline-offset));
    }

    &:before {
        background-color: transparent;
    }

    &:after {
        background-color: transparent;
    }

    &:focus,
    &.focus {
        &:before {
            box-shadow: 0 0 0 var(--hover-outline-width) var(--hover-outline-color),
                0 0 0 calc(var(--hover-outline-offset)) rgba(81, 183, 238, 0.5);
        }
    }

    &:hover {
        --hover-outline-width: 5px;
    }

    &.disabled,
    &:disabled {
        --hover-outline-width: 3px;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        --hover-outline-width: 3px;
    }
}

.btn-flavour--login-alt {
    --color: var(--text-color);
    --hover-outline-color: var(--text-color);
    --background-color: var(--text-color);
    --hover-outline-width: 2px;
    --hover-outline-offset: 1px;

    width: 100%;

    &:hover {
        --hover-outline-width: 3px;
    }

    .icon {
        width: 30px !important;
        height: 30px;
    }
}

.btn-flavour--alt-border {
    --color: var(--brand-color-12);
    --hover-outline-color: var(--button-alt-hover-border-color);
}

.btn-flavour--danger {
    --background-color: var(--button-background-color-danger, red);
}

.btn-flavour--alert {
    --background-color: var(--button-background-color-alert, orange);
}

.btn-flavour--register {
    --background-color: var(--brand-color-4, blue);
}

.btn-flavour--round {
    --radius: 50%;
}

.btn-flavour--dropdown {
    width: 100%;
    text-align: left;
    padding: 0 20px;
    margin: 0;
    border-radius: 0;
    border-color: transparent;

    &:before {
        border-radius: 0;
        background-color: var(--background-color);
        left: 0;
    }
}

.btn-flavour--question-option {
    height: auto;
    white-space: normal;

    --top: 10px;
    --bottom: 10px;
    --radius: 10px;
    --background-color: transparent;
    --color: var(--brand-color-5);
    // --hover-border-width: 3px;
    --hover-outline-width: 3px;
    --hover-outline-color: var(--color);

    &:hover {
        --background-color: var(--brand-color-5);
        --color: var(--text-color-contrast);
        --hover-outline-color: var(--background-color);
        --hover-outline-width: 3px;
    }

    // &.disabled,
    // &:disabled {
    //     // --hover-border-width: 0px;
    // }

    // &:not(:disabled):not(.disabled):active,
    // &:not(:disabled):not(.disabled).active,
    // .show > &.dropdown-toggle {
    //     // --hover-border-width: 0px;
    //     // &:before {
    //     //     border: var(--hover-border-width) solid transparent;
    //     // }
    // }
}

.btn-nav {
    width: 100%;
    text-align: left;
    padding: 0 20px;
    margin: 0;
    border-radius: 0;
    border-color: transparent;
}

.btn-nav-alt {
    width: 100%;
    text-align: left;
    padding: 0 20px;
    margin: 0;
    border-radius: 0;
    border-color: transparent;
}

.btn-question-option {
    $color: var(--brand-color-5);
    $color-hover: var(--text-color-contrast);
    $active-background: var(--brand-color-5);
    $active-border: var(--brand-color-5);
    color: $color;
    border-color: $color;

    &:hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    }

    &:disabled {
        pointer-events: none;
    }
}

.back-arrow-button {
    svg {
        margin-right: 6px;
    }
}

.card-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    grid-gap: 15px;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

.Box {
    display: flex;
    flex-direction: column;
    background-color: var(--box-background-color);
    border-radius: var(--box-border-radius);
    padding: var(--box-padding-small-topbottom) var(--box-padding-small-leftright);
    @include media-breakpoint-up(sm) {
        padding: var(--box-padding-large-topbottom) var(--box-padding-large-leftright);
    }
    margin-bottom: var(--box-margin-bottom);
    width: 100%;
    max-width: var(--box-max-width);
    box-shadow: var(--box-shadow-horizontal-length) var(--box-shadow-vertical-length) var(--box-shadow-blur) 0px
        rgba(var(--box-shadow-color), var(--box-shadow-opacity));

    &.alt {
        background-color: var(--box-background-color)-alt;
    }
}

.card {
    --card-h: calc(var(--card-ratio-portrait) * var(--card-width));

    width: var(--card-width);
    height: var(--card-h);
    // flex-shrink: 0;
    // flex-basis: $width;

    // margin: var(--card-gap);
    // margin: auto;
    justify-self: center;
    align-self: center;

    @include media-breakpoint-up(sm) {
        --card-width: 220px;
    }
    @include media-breakpoint-up(md) {
        --card-width: 220px;
    }
    @include media-breakpoint-up(lg) {
        --card-width: 300px;
    }
}

.dialog {
    background-color: var(--brand-danger-color) !important;
    color: var(--text-color-contrast) !important;

    .actions {
        padding: 1rem !important;
    }
}

.dialog-warning {
    background-color: var(--button-background-color-alert) !important;
    color: var(--text-color-contrast) !important;

    .actions {
        padding: 1rem !important;
    }
}

.modal {
    background-color: var(--brand-danger-color) !important;
    color: var(--text-color-contrast) !important;
    margin-bottom: 1rem;

    h2 {
        margin-bottom: 0.75rem;
    }
}

.modal-warning {
    background-color: var(--button-background-color-alert);
    color: var(--text-color-contrast);
    margin-bottom: 1rem;

    h2 {
        margin-bottom: 0.75rem;
    }
}

.deck {
    --deck-width: var(--deck-width-large);
    --deck-h: calc(var(--card-ratio-landscape) * var(--deck-width-large));

    width: var(--deck-width);
    height: var(--deck-h);

    justify-self: center;
    align-self: center;

    border-radius: var(--deck-border-radius);
}

.transform3djagggedlinesfix {
    outline: 1px solid transparent;
}

.popup-content {
    margin: auto;
    width: 100%;
    max-width: 600px;
}
[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
}
.popup-overlay {
    background: linear-gradient(180deg, var(--popup-overlay-color-1) 0%, var(--popup-overlay-color-2) 100%);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}
.popup-arrow {
    -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
    color: #fff;
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
}

.spinner {
    $spinner-width: 50px;
    width: $spinner-width !important;
    height: calc(#{var(--card-ratio-portrait)} * #{$spinner-width});
    border-radius: 5px;
    box-shadow: none;

    margin: 30% auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.app-loading {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1;
    width: 100vw;
    height: 100vh;

    .loading-text {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
}

.general-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8c92ac;

    .spinner {
        background-color: #8c92ac;
    }
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }
    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

.wrap-text {
    word-break: break-word;
    overflow-wrap: anywhere;
}

@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin multiLineEllipsis($maxlines: 2, $lh: 1rem) {
    display: -webkit-box;
    // max-height: calc(#{$maxlines} * #{$lh});
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: anywhere;
    -webkit-line-clamp: #{$maxlines};
}

.wrap-text-overflow-1 {
    @include multiLineEllipsis(1, 1rem);
}
.wrap-text-overflow-2 {
    @include multiLineEllipsis(2, 1rem);
}
.wrap-text-overflow-3 {
    @include multiLineEllipsis(3, 1rem);
}

.scroll-data {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #fff;
}

.scroll-data__inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
}

.data-item {
    padding: 5px;
    flex: 0 0 25%;
}

.data-item__inner {
    padding: 20px;
    background-color: #f3f3f3;
}

.data-item__label {
    font-weight: var(--font-weight-bold);
    margin-bottom: 10px;
}

.data-item__value {
    font-variant-numeric: tabular-nums;
}

.page-container {
    width: 1020px;
    margin: 0 auto 250px;
    padding: 20px;
    line-height: 2;
}

@media (max-width: 1000px) {
    .scroll-data {
        padding: 20px;
    }

    .data-item {
        flex: 0 0 50%;
    }
}

.special-p {
    overflow-wrap: break-word;
    word-break: break-word;
}

.quest-progress-background {
    background: var(--quest-progress-background) !important;
}

.flex-grow {
    flex-grow: 1;
}

// Quick fix TODO: move to form styling
form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

// .form-control:disabled,
// .form-control[readonly] {
//     background-color: transparent !important;
// }

.tagify {
    margin-bottom: 0.5rem;
    --tag-bg: #1e8691;
    --tag-text-color: white;
    --tag-text-color--edit: var(--tag-bg);
    --tag-hover: rgba(41, 171, 186, 1);
    --tag-remove-btn-color: white;
    // --tag-remove-btn-bg--hover: var(--brand-color-1);
    --tag-pad: 0.3rem 0.5rem 0.3rem 1rem;

    // --tag-remove-bg
    --tag-invalid-color: var(--brand-color-1);
    --tag-invalid-bg: var(--brand-color-1);

    border-radius: 6px;
    background-color: var(--input-background-color);
}

.tagify__tag > div {
    border-radius: 25px;
}

.tagify__tag__removeBtn {
    margin-right: 0.5rem;
}

.tagify__input::before {
    height: inherit !important;
    line-height: inherit !important;
}

:root {
    --background-color: #1e8691;
}

.react-select {
    &-container {
        --select-background-color: #1e8691;
    }

    &__control {
        border: none !important;
        border-bottom: 1px solid var(--brand-color-5) !important;
        border-radius: 0px !important;
        background-color: rgba(var(--brand-color-5-rgb), 0.09) !important;

        &--is-focused {
            background-color: rgba(var(--brand-color-5-rgb), 0.09) !important;
        }

        box-shadow: none !important;
    }

    &__value-container {
        padding: 10px !important;
    }
    &__menu {
        z-index: 3 !important;
    }

    &__indicator-separator {
        background-color: transparent !important;
    }

    &__multi-value {
        align-items: center;
        background-color: var(--brand-color-5) !important;
        border-radius: 25px !important;

        &__label {
            padding: 0.3rem 0.5rem 0.3rem 1rem !important;
            color: white !important;
        }

        &__remove {
            order: 5;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50% !important;
            cursor: pointer;
            background: none;
            color: white;
            width: 14px;
            height: 14px;
            margin-left: auto;
            overflow: hidden;
            transition: 0.2s ease-out;
            padding: 0 !important;

            margin-right: 0.5rem;

            &:hover {
                background: #c77777 !important;
                color: white !important;
            }

            svg {
                display: none;
            }

            &:after {
                content: '\00D7';
                transition: 0.3s, color 0s;
            }
        }
    }
}

[style*='--aspect-ratio'] > :first-child {
    width: 100%;
}
[style*='--aspect-ratio'] > img {
    height: auto;
}
@supports (--custom: property) {
    [style*='--aspect-ratio'] {
        position: relative;
    }
    [style*='--aspect-ratio']::before {
        content: '';
        display: block;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*='--aspect-ratio'] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    color: black !important;
    border: 1px dashed lightgray !important;
}

.custom-date-input {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &-wrapper {
        display: flex;
        align-items: center;

        input {
            width: 100%;
        }
    }

    svg {
        font-size: $h4-font-size;
        margin: 0 0 0 0.5rem;
    }
}

.mce-content-body {
    padding: 18.5px 14px;
    background-color: #fff;
    background-clip: padding-box;
    // border: 1px solid #ced4da;
    width: 100%;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: transparent;
}

.mce-edit-focus {
    // margin-top: 40px;
    background-color: white !important;
}

.element-type-image {
    color: var(--input-background-color);
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
    appearance: none;
}

@media (min-width: 600px) {
    .MuiSnackbar-anchorOriginTopRight {
        left: 24px !important;
    }
}

.react-flow__edge {
    pointer-events: all;
}

// .react-flow__edge-custom.selected {
//     .react-flow__edge-path {
//         stroke: red !important;
//         stroke-width: 4 !important;
//     }

//     #markerArrow {
//         stroke: red;
//         fill: red;
//     }
// }

.react-flow__node-cardNode.selectable:hover,
.react-flow__node-cardNode.selected {
    .CardNode {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}

.MuiTableBody-root,
.MuiTableHead-root {
    tr {
        &:not(:last-child) {
            border-bottom: 1px solid var(--divider-color);
        }

        td {
            border-bottom: none;
        }
    }
}

.table-header {
    color: var(--brand-color-5);
    font-weight: var(--header-font-weight);
    font-family: var(--header-font-family);
    line-height: 1.5;
}

.MuiPrivateNotchedOutlineRoot {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;
}

.MuiPrivateNotchedOutlineLegendLabelled {
    width: auto;
    height: 11px;
    display: block;
    padding: 0;
    font-size: 0.75em;
    max-width: 0.01px;
    text-align: left;
    transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    visibility: hidden;

    > span {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        font-family: var(--header-font-family);
    }
}

.MuiPrivateNotchedOutlineLegendNotched {
    max-width: 1000px;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
}

.MuiOutlinedInput-notchedOutline {
    legend {
        span {
            font-family: var(--header-font-family);
        }
    }
}
