@import 'scss/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.GroupDetail {
    max-width: 1000px;
    align-self: center;
    width: 100%;
}

.GroupDetailHeader {
    margin-bottom: 3rem;
}

.GroupTitle {
    // margin: 0;
    color: var(--text-color-contrast);
}

.GroupSubtitle {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.formGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
}

.formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    button:first-child {
        margin-right: auto;
    }
}

.flexGap {
    --gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
}

.flexGap > * {
    margin: var(--gap) 0 0 var(--gap);
}
