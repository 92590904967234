@import 'scss/globals';

.Header {
    &.bottomMargin {
        margin-bottom: 1.25rem;

        @media (min-width: 768px) {
            margin-bottom: 1.5rem;
        }
    }
}

.Top {
    &.underline {
        border-bottom: 1px solid var(--text-color-contrast);
        margin-bottom: 0.25rem;
    }

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (min-width: 768px) {
        align-items: center;
    }
}

.Bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    color: var(--text-color-contrast);
    margin-bottom: 0.75rem;
}

.Subtitle,
.RightAlignedText {
    font-size: $h7-font-size;
    color: var(--text-color-contrast);
}

.RightAlignedText {
    text-align: right;
}

.EditButton {
    padding: 0;
    --size: 40px;
    flex-shrink: 0;

    @media (min-width: 768px) {
        --size: 50px;
    }
}
