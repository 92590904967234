@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.DeckActions {
    display: flex;
}

.Actionbar {
    width: 100vw;

    border-radius: var(--box-border-radius);

    &.hidden {
        transform: translateY(30px);
        opacity: 0;
    }
}

.ActionbarSticky {
    position: fixed;
    bottom: 0;
    left: 0px;
    right: 0px;
    z-index: var(--z-index-deck-actions-bar);
    transform: translateY(100%);

    &.isSticky {
        transform: translateY(0);
    }

    &.hidden {
        height: 0px;
    }
}

.Actionbar,
.ActionbarSticky {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    --background-color: var(--brand-alert-color);
    background: var(--background-color);
    min-height: 50px;

    transition: transform 0.35s cubic-bezier(0.49, 0.8, 0, 1.28);

    &.published {
        --background-color: var(--brand-danger-color);
    }

    &.concept {
        --background-color: var(--brand-alert-color);
    }

    --actionbar-page-padding: var(--page-padding);
    padding: 0.75rem var(--actionbar-page-padding);
}

.ActionbarSticky {
    // calc padding for page breakpoints

    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.2);

    --actionbar-breakpoint: 0px;

    @include media-breakpoint-up(sm) {
        --actionbar-breakpoint: 576px;
        --actionbar-page-padding: var(--page-padding);
        padding: 0.75rem calc((100vw - calc(var(--actionbar-breakpoint) - (var(--actionbar-page-padding) * 2))) / 2);
    }
    @include media-breakpoint-up(md) {
        --actionbar-breakpoint: 768px;
        --actionbar-page-padding: var(--page-padding-desktop);
    }
    @include media-breakpoint-up(lg) {
        --actionbar-breakpoint: 992px;
        --actionbar-page-padding: var(--page-padding-desktop);
    }
    @include media-breakpoint-up(xl) {
        --actionbar-breakpoint: 1000px;
        --actionbar-page-padding: 0px;
    }
}

.LeftActions,
.RightActions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.PublishedStateLabel {
    color: var(--text-color-contrast);
}

// .ArrowIcon {
//     @include media-breakpoint-up(sm) {
//         font-size: $h4-font-size;
//     }
// }

.Previous {
    .ArrowIcon {
        margin-right: 3px;
    }
}

.Previous,
.Next {
    &.enabled {
        opacity: 1;
    }
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}

.InfoContainer {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;

    color: var(--text-color);

    &.canManage {
        color: var(--text-color-contrast);
    }
}

.TitleContainer {
    display: flex;
    gap: 0.5rem;

    font-weight: bold;
    font-size: 1rem;
}
